import { Form, DatePicker, Input, Layout, notification, Radio, Select } from "antd";
import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import locale from "antd/es/date-picker/locale/tr_TR";
import dayjs from "dayjs";
import "dayjs/locale/tr.js";

const { Header, Content } = Layout;

function BelgeGuncelle(props) {
    const { t } = useTranslation();
    const [docForm] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [belgeTipiID, setBelgeTipiID] = useState();
    const [belgeIsmi, setbelgeIsmi] = useState();
    const [gecerlikTarihi, setgecerlikTarihi] = useState();
    const [belgeDurumu, setBelgeDurumu] = useState();
    const [fileList, setFileList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        setBelgeTipiID(props.fieldValues.belgeTipiID);
        setbelgeIsmi(props.fieldValues.belgeIsmi);
        setTypeList(props.typeList);
        docForm.setFieldsValue({
            id: props.fieldValues.id,
            belgeTipiID: props.fieldValues.belgeTipiID,
            belgeIsmi: props.fieldValues.belgeIsmi,
            durumAciklamasi: props.fieldValues.durumAciklamasi,
            gecerlikTarihi: props.fieldValues.gecerlikTarihi ? dayjs(props.fieldValues.gecerlikTarihi) : null,
        });
    }, [props]);

    const onSubmit = (e) => {
        console.log("doc update : ", e);
        fetch(`http://89.19.24.18:3001/Belge/byId/${props.fieldValues.id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("update cevap : ", data);
            });
        setLoading(false);
    };

    function onSelectDate(date, dateString) {
        console.log(date, dateString);
        setgecerlikTarihi(dateString);
    }

    return (
        <Layout style={{ padding: 30 }}>
            <Content style={{ padding: "0px" }}>
                <Form
                    name="docUpdateForm"
                    form={docForm}
                    autoComplete="off"
                    layout="vertical"
                    style={{ margin: 30 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ");
                        console.log(error);
                    }}
                >
                    {contextHolder}
                    <Form.Item name="belgeTipiID" label={t("belge.belgeTipi")} rules={[{ required: true, message: t("genel.gerekli") }]}>
                        <Select placeholder={t("genel.seciniz")} onChange={(val) => setBelgeTipiID(val)}>
                            {typeList.map((item, index) => (
                                <Select.Option value={item.id} key={index}>
                                    {item.belgeTipIsmi}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="belgeIsmi" label={t("belge.belgeIsmi")} maxLength={255}>
                        <Input placeholder={t("belge.belgeIsmiHint")} onChange={(e) => setbelgeIsmi(e.target.value)} />
                    </Form.Item>
                    <Form.Item name="gecerlikTarihi" label={t("belge.gecerlikTarihi")}>
                        <DatePicker style={{ width: "100%" }} locale={locale} placeholder={t("belge.gecerlikTarihi")} onChange={onSelectDate} />
                    </Form.Item>
                    <Form.Item name="belgeDurumu" label={t("belge.belgeDurumu")}>
                        <Radio.Group
                            value={belgeDurumu}
                            onChange={(value) => {
                                setBelgeDurumu(value);
                            }}
                            style={{ width: "100%" }}
                        >
                            <Radio.Button value="NEW">{t("belgeDurumu.yeni")}</Radio.Button>
                            <Radio.Button style={{ color: "green" }} value="evet">
                                {t("belgeDurumu.evet")}
                            </Radio.Button>
                            <Radio.Button style={{ color: "red" }} value="hayir">
                                {t("belgeDurumu.hayir")}
                            </Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="durumAciklamasi" label={t("belge.durumAciklamasi")} maxLength={255}>
                        <Input placeholder={t("belge.durumAciklamasiHint")} onChange={(e) => setbelgeIsmi(e.target.value)} />
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
}

export default BelgeGuncelle;
