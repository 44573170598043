import { Avatar, Button, Image, Input, Layout, Modal, notification, Space, Spin, Table } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SearchOutlined, EditOutlined } from "@ant-design/icons";
import KmyHeader from "./KmyHeader";
import TrainingAdd from "./EgitimEkle";
import { useEgitimleriGetir } from "../hooks/useEgitim";
import { useGetUserInfo } from "../hooks/useGetUserInfo";
const { Header, Content } = Layout;

function EgitimList() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [filteredSource, setFilteredSource] = useState([]);
    const [errMessage, setErrMessage] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const { kisiID, firmaID } = useGetUserInfo();
    const { egitimler } = useEgitimleriGetir();

    useEffect(() => {
        setDataSource(egitimler);
        setFilteredSource(egitimler);
        setIsPending(false);
    }, [egitimler]);

    const addButton = (
        <Button
            key="addButton"
            type="primary"
            htmlType="submit"
            loading={isPending}
            danger={errMessage}
            size="small"
            onClick={() => {
                setModalOpen(true);
            }}
        >
            {t("genel.ekle")}
        </Button>
    );

    const searchData = (searchText) => {
        setFilteredSource(dataSource);
        if (searchText) {
            setFilteredSource(
                dataSource.filter((item) => {
                    const egitimIsmi = item.egitimIsmi ? item.egitimIsmi.toLowerCase() : "";
                    const comparisonString = `${egitimIsmi}`;
                    return comparisonString.includes(searchText.toLowerCase());
                })
            );
        }
    };

    const columns = [
        {
            title: "",
            width: 40,
            key: (p) => p.id,
            render: (p) => (
                <Button
                    type="text"
                    key={p.egitimID}
                    icon={<EditOutlined />}
                    style={{ marginRight: -15 }}
                    onClick={() => {
                        navigate(`/egitimGuncelle/${p.egitimID}`);
                    }}
                />
            ),
        },
        {
            title: t("csgb.group"),
            dataIndex: "csgbGrup",
            key: (p) => p.id & "csgbGrup",
            sorter: (a, b) => a.csgbGrup.localeCompare(b.csgbGrup),
            render: (p) => <div>{p && t(`csgb.${p}`)}</div>,
            filters: [
                { value: "1", text: "Genel konular" },
                { value: "2", text: "Sağlık konuları" },
                { value: "3", text: "Teknik konular" },
                { value: "4", text: "Diğer konular" },
            ],
            onFilter: (value, record) => record.kmyMusterisiMi === value,
        },
        {
            title: t("csgb.subGroup"),
            dataIndex: "csgbAltGrup",
            key: (p) => `${p.id}csgbAltGrup`,
            sorter: (a, b) => a.csgbAltGrup.localeCompare(b.csgbAltGrup),
            render: (p) => <div>{p && t(`csgb.${p}`)}</div>,
            filters: [
                { value: "110", text: "Çalışma mevzuatı ile ilgili bilgiler" },
                { value: "120", text: "Çalışanların yasal hak ve sorumlulukları" },
                { value: "130", text: "İşyeri temizliği ve düzeni" },
                { value: "140", text: "İş kazası ve meslek hastalığından doğan hukuki sonuçlar" },
                { value: "210", text: "Meslek hastalıklarının sebepleri" },
                { value: "220", text: "Hastalıktan korunma prensipleri ve korunma tekniklerinin uygulanması" },
                { value: "230", text: "Biyolojik ve psikososyal risk etmenleri" },
                { value: "240", text: "İlkyardım" },
                { value: "310", text: "Kimyasal, fiziksel ve ergonomik risk etmenleri" },
                { value: "320", text: "Elle kaldırma ve taşıma" },
                { value: "330", text: "Parlama, patlama, yangın ve yangından korunma" },
                { value: "340", text: "İş ekipmanlarının güvenli kullanımı" },
                { value: "350", text: "Ekranlı araçlarla çalışma" },
                { value: "360", text: "Elektrik, tehlikeleri, riskleri ve önlemleri" },
                { value: "370", text: "İş kazalarının sebepleri ve korunma prensipleri ile tekniklerinin uygulanması" },
                { value: "380", text: "Güvenlik ve sağlık işaretleri" },
                { value: "390", text: "Kişisel koruyucu donanım kullanımı" },
                { value: "395", text: "İş sağlığı ve güvenliği genel kuralları ve güvenlik kültürü" },
                { value: "399", text: "Tahliye ve kurtarma" },
                { value: "401", text: "Yüksekte çalışma" },
                { value: "402", text: "Kapalı ortamda çalışma" },
                { value: "403", text: "Radyasyon riskinin bulunduğu ortamlarda çalışma" },
                { value: "404", text: "Kaynakla çalışma" },
                { value: "404", text: "Sıcak çalışma" },
                { value: "404", text: "Özel risk taşıyan ekipman ile çalışma" },
                { value: "404", text: "Kanserojen maddelerin yol açtığı olası sağlık riskleri" },
            ],
            onFilter: (value, record) => record.kmyMusterisiMi === value,
        },
        {
            title: t("egitim.egitimIsmi"),
            dataIndex: "egitimIsmi",
            key: (p) => p.id & "egitimIsmi",
            sorter: (a, b) => a.egitimIsmi.localeCompare(b.egitimIsmi),
        },
        {
            title: t("egitim.aciklama"),
            dataIndex: "aciklama",
            key: (p) => p.id & "aciklama",
            sorter: (a, b) => a.aciklama.localeCompare(b.aciklama),
        },
    ];

    const onChange = (pagination, filters, sorter, extra) => {
        console.log("params", sorter, filters);
    };

    return isPending ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <KmyHeader caller="trainingList" buttons={[addButton]} backURL="/" />
                {contextHolder}
                <Input
                    key="searchBox"
                    placeholder={t("genel.search")}
                    prefix={<SearchOutlined />}
                    style={{ margin: 10, width: 300 }}
                    onChange={(e) => {
                        searchData(e.target.value);
                    }}
                />
                <Table key="egitimList" dataSource={filteredSource} columns={columns} onChange={onChange} />
                <Modal
                    key="modalForm"
                    open={modalOpen}
                    onOk={() => {
                        setModalOpen(false);
                    }}
                    onCancel={() => {
                        setModalOpen(false);
                    }}
                    footer={[
                        <Button
                            key="cancel"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            style={{ marginRight: 20 }}
                        >
                            {t("genel.vazgec")}
                        </Button>,
                        <Button
                            key="ok"
                            type="primary"
                            form="egitimEkleForm"
                            htmlType="submit"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                        >
                            {t("genel.kaydet")}
                        </Button>,
                    ]}
                >
                    <TrainingAdd />
                </Modal>
            </Content>
        </Layout>
    );
}

export default EgitimList;
