import { useEffect, useState } from "react";
import { useGetToken } from "./useGetToken";

export const useFirmaEkle = () => {
    const { accessToken } = useGetToken();

    const firmaEkle = async ({ firmaismi, firmaUnvani, ulke, il, ilce, vergiNo }) => {
        fetch(`http://89.19.24.18:3001/Firma/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify({
                firmaismi: firmaismi ? firmaismi : "",
                firmaUnvani: firmaUnvani ? firmaUnvani : "",
                ulke: ulke ? ulke : "",
                il: il ? il : "",
                ilce: ilce ? ilce : "",
                vergiNo: vergiNo ? vergiNo : "",
            }),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("add cevap : ", data);
            });
    };

    return { firmaEkle };
};

export const useFirmaGuncelle = (id, e) => {
    const { accessToken } = useGetToken();
    const firmaGuncelle = async ({ id, e }) => {
        fetch(`http://89.19.24.18:3001/Firma/byId/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("update cevap : ", data);
            });
    };
    return { firmaGuncelle };
};

export const useFirmaSil = (id) => {
    const { accessToken } = useGetToken();
    const firmaSil = async () => {
        fetch(`http://89.19.24.18:3001/Firma/byId/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("delete cevap : ", data);
            });
    };
    return { firmaSil };
};

export const useFirmalariGetir = () => {
    const { accessToken } = useGetToken();
    const [firmalar, setFirmalar] = useState([]);

    const firmalariGetir = async () => {
        fetch("http://89.19.24.18:3001/Firma", { headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` } })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setFirmalar(data);
            });

        return () => firmalar();
    };

    useEffect(() => {
        firmalariGetir();
    }, []);

    return { firmalar };
};

export const useFirmayiGetir = (id) => {
    const { accessToken } = useGetToken();
    const [firma, setFirma] = useState([]);
    const firmayiGetir = async () => {
        if (id) {
            fetch(`http://89.19.24.18:3001/Firma/byId/${id}`, {
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    setFirma(data);
                });
        }
        return () => firma;
    };

    useEffect(() => {
        firmayiGetir();
    }, []);

    return { firma };
};
