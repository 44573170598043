import { Form, Button, DatePicker, Input, Layout, message, notification, Select, Upload } from "antd";
import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetUserInfo } from "../hooks/useGetUserInfo";
import { useBildirimEkle } from "../hooks/useBildirim";

const { Header, Content } = Layout;
const { TextArea } = Input;

function BildirimEkle(props) {
    const { t, i18n } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const { kisiID, firmaID } = useGetUserInfo();
    const { bildirimEkle } = useBildirimEkle();

    const onSubmit = async (e) => {
        console.log(props);
        bildirimEkle({
            yer: props.caller,
            tur: "YO",
            aciklama: e.aciklama,
            cozumOnerisi: e.cozumOnerisi,
            firmaID: firmaID,
            kisiID: kisiID,
        });
        api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
    };

    return (
        <Layout style={{ padding: 30 }}>
            <Content style={{ padding: "0px" }}>
                <Form
                    name="bildirimEkleForm"
                    autoComplete="off"
                    layout="vertical"
                    style={{ marginTop: 30 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                >
                    {contextHolder}
                    <Form.Item name="aciklama" label={t("bildirim.aciklama")}>
                        <TextArea rows="6" />
                    </Form.Item>
                    <Form.Item name="cozumOnerisi" label={t("bildirim.cozumOnerisi")}>
                        <TextArea rows="6" />
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
}

export default BildirimEkle;
