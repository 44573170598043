import { Form, Input, Layout } from "antd";
import { React } from "react";
import { useTranslation } from "react-i18next";
import { useKursSoruEkle } from "../hooks/useKursSoru";

const { Content } = Layout;

function KursSoruEkle(props) {
    const { t } = useTranslation();
    const { kursSoruEkle } = useKursSoruEkle();

    const onSubmit = (e) => {
        console.log("ekle : ", props, e);
        kursSoruEkle({
            kursID: props.kursID,
            soru: e.soru,
        });
    };

    return (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <Form
                    id="kursSoruEkleForm"
                    autoComplete="off"
                    layout="vertical"
                    style={{ margin: 30 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ");
                        console.log(error);
                    }}
                >
                    <Form.Item name="soru" label={t("kursSoru.soru")} rules={[{ required: true, message: t("genel.gerekli") }, { whitespace: true }]}>
                        <Input placeholder={t("kursSoru.soruHint")} />
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
}

export default KursSoruEkle;
