import csgbSubject from "../constant/csgbSubject.json";

import { Form, Button, Cascader, DatePicker, Input, Layout, List, Modal, notification, Popconfirm, Select, Space, Spin, Tabs, Tooltip } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import KmyHeader from "./KmyHeader";
import locale from "antd/es/date-picker/locale/tr_TR";
import dayjs from "dayjs";
import "dayjs/locale/tr.js";
import { ImportOutlined } from "@ant-design/icons";
import KisiAktar from "./KisiAktar";
import { useKisileriGetir } from "../hooks/useKisi";
import { useEgitimiGetir, useEgitimGuncelle, useEgitimSil, useOgrencileriGetir } from "../hooks/useEgitim";

const { Content } = Layout;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

function EgitimGuncelle() {
    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isPending, setIsPending] = useState();
    const [errMessage, setErrMessage] = useState(null);
    const [api, contextHolder] = notification.useNotification();
    const { kisiler } = useKisileriGetir();

    const yetkiler = [99999]; //auth.permissions;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [importModalOpen, setImportModalOpen] = useState(false);

    const { egitim } = useEgitimiGetir(id);
    const { egitimGuncelle } = useEgitimGuncelle(id);
    const { egitimSil } = useEgitimSil(id);
    const { ogrenciler } = useOgrencileriGetir(id);
    const [egitmens, setTrainers] = useState([]);

    const egitimKaydetButon = (yetkiler.includes(10201) || yetkiler.includes(99999)) && (
        <Button key="egitimKaydetButon" type="primary" htmlType="submit" loading={isPending} danger={errMessage}>
            {t("genel.kaydet")}
        </Button>
    );

    const egitimSilButon = (yetkiler.includes(10202) || yetkiler.includes(99999)) && (
        <Popconfirm
            key="egitimSilOnay"
            placement="bottomRight"
            title={t("genel.eminMisin")}
            description={t("genel.silinecek")}
            okText={t("genel.sil")}
            cancelText={t("genel.vazgec")}
            onConfirm={() => {
                egitimSil(id);
                navigate("/egitimList");
            }}
            style={{ width: 400 }}
        >
            <Button key="egitimSilButon" type="primary" danger>
                {t("genel.sil")}
            </Button>
        </Popconfirm>
    );

    useEffect(() => {
        if (kisiler) {
            const dropdownItemsPerson = kisiler.map((item) => ({ value: String(item.kisiID), label: `${item.isim} ${item.soyad}` }));
            setTrainers(dropdownItemsPerson);
        }
    }, [kisiler]);

    useEffect(() => {
        if (egitim) {
            form.setFieldsValue({
                egitimIsmi: egitim.egitimIsmi,
                aciklama: egitim.aciklama,
                egitimZamani: [egitim.baslangicZamani ? dayjs(egitim.baslangicZamani) : null, egitim.bitisZamani ? dayjs(egitim.bitisZamani) : null],
                kacDakika: egitim.kacdakika,
                egitimYeri: egitim.egitimyeri,
                egitmen: egitim.egitmen,
                gecmeNotu: egitim.gecmeNotu,
                csgbGrup: egitim.csgbGrup,
                csgbAltGrup: egitim.csgbAltGrup,
                csgbKonu: [egitim.csgbGrup, egitim.csgbAltGrup],
                csgbYer: egitim.csgbYer,
                csgbTip: egitim.csgbTip,
            });
            setLoading(false);
        }
    }, [egitim]);

    const onSubmit = (e) => {
        setIsPending(true);
        if (e.csgbKonu == null) {
            e.csgbGrup = "";
            e.csgbAltGrup = "";
            e.csgbKonu = "";
        } else {
            e.csgbGrup = e.csgbKonu[0];
            e.csgbAltGrup = e.csgbKonu[1];
            e.csgbKonu = "";
        }
        e.baslangicZamani = e.egitimZamani[0];
        e.bitisZamani = e.egitimZamani[1];
        console.log("form bilgisi : ", e);
        egitimGuncelle({ id, e });
        setIsPending(false);
        api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
    };

    return loading ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout style={{ margin: 0, padding: 0 }}>
            <Content style={{ padding: "0px" }}>
                <Form
                    form={form}
                    disabled={!yetkiler.includes(10201) && !yetkiler.includes(99999)}
                    autoComplete="off"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ", error);
                    }}
                >
                    <KmyHeader
                        key="egitimGuncelleKmyHeader"
                        caller="egitimGuncelle"
                        buttons={[egitimKaydetButon, egitimSilButon]}
                        backURL="/egitimList"
                    />
                    {contextHolder}
                    <Tabs
                        centered
                        type="card"
                        defaultActiveKey="1"
                        style={{ margin: 20 }}
                        items={[
                            {
                                key: "1",
                                label: t("egitim.tabGenel"),
                                children: (
                                    <>
                                        <Form.Item
                                            name="egitimIsmi"
                                            label={t("egitim.egitimIsmi")}
                                            rules={[{ required: true, message: t("genel.gerekli") }, { whitespace: true }]}
                                            style={{ marginTop: 20 }}
                                        >
                                            <Input placeholder={t("egitim.egitimIsmiHint")} />
                                        </Form.Item>
                                        <Form.Item
                                            name="aciklama"
                                            label={t("egitim.aciklama")}
                                            rules={[{ required: true, message: t("genel.gerekli") }, { whitespace: true }]}
                                        >
                                            <TextArea rows={5} placeholder={t("egitim.aciklamaHint")} />
                                        </Form.Item>

                                        <Form.Item name="egitmen" label={t("egitim.egitmen")}>
                                            <Select mode="multiple" placeholder={t("egitim.egitmenHint")}>
                                                {egitmens.map((item, index) => (
                                                    <Select.Option value={item.value} key={index}>
                                                        {item.label}
                                                    </Select.Option>
                                                ))}{" "}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="kacDakika" label={t("egitim.kacdakika")}>
                                            <Input placeholder={t("egitim.kacdakikaHint")} />
                                        </Form.Item>
                                        <Form.Item name="egitimYeri" label={t("egitim.egitimyeri")}>
                                            <Input placeholder={t("egitim.egitimyeriHint")} />
                                        </Form.Item>
                                        <Form.Item name="egitimZamani" label={t("egitim.egitimZamani")}>
                                            <RangePicker showTime style={{ width: "100%" }} />
                                        </Form.Item>
                                        <Form.Item name="gecmeNotu" label={t("egitim.gecmeNotu")}>
                                            <Input placeholder={t("egitim.gecmeNotuHint")} />
                                        </Form.Item>
                                        <Form.Item name="csgbKonu" label={t("egitim.csgbKonu")}>
                                            <Cascader options={csgbSubject} placeholder={t("genel.seciniz")} />
                                        </Form.Item>
                                        <Form.Item name="csgbYer" label={t("egitim.csgbYer")}>
                                            <Select placeholder={t("egitim.csgbYer")}>
                                                <Select.Option key="csgbYer0" value="0">
                                                    {t("egitim.csgbYer0")}
                                                </Select.Option>
                                                <Select.Option key="csgbYer1" value="1">
                                                    {t("egitim.csgbYer1")}
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="csgbTip" label={t("egitim.csgbTip")}>
                                            <Select placeholder={t("egitim.csgbTip")}>
                                                <Select.Option key="csgbTip0" value="0">
                                                    {t("egitim.csgbTip0")}
                                                </Select.Option>
                                                <Select.Option key="csgbTip1" value="1">
                                                    {t("egitim.csgbTip1")}
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "2",
                                label: t("egitim.tabSinif"),
                                children: (
                                    <List
                                        style={{ margin: 20 }}
                                        header={
                                            <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                                <Button
                                                    key="importPersonFormOpen"
                                                    type="dashed"
                                                    icon={<ImportOutlined />}
                                                    onClick={() => {
                                                        setImportModalOpen(true);
                                                    }}
                                                >
                                                    Excel dosyası ile katılımcı yükle
                                                </Button>
                                            </Space>
                                        }
                                        bordered
                                        dataSource={ogrenciler}
                                        renderItem={(item) => (
                                            <List.Item key={item.id}>
                                                <Space style={{ width: "100%", justifyContent: "space-between" }}>
                                                    {`${item.isim} ${item.soyad}`}
                                                </Space>
                                            </List.Item>
                                        )}
                                    />
                                ),
                            },
                        ]}
                    />
                </Form>
                <Modal
                    key="importModalForm"
                    open={importModalOpen}
                    onOk={() => {
                        setImportModalOpen(false);
                    }}
                    onCancel={() => {
                        setImportModalOpen(false);
                    }}
                    footer={[
                        <Button
                            key="cancel"
                            onClick={() => {
                                setImportModalOpen(false);
                            }}
                            style={{ marginRight: 20 }}
                        >
                            {t("genel.kapat")}
                        </Button>,
                    ]}
                >
                    <KisiAktar egitimID={id} />
                </Modal>
            </Content>
        </Layout>
    );
}

export default EgitimGuncelle;
