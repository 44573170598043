import { Form, Input, Layout, notification } from "antd";
import { React } from "react";
import { useTranslation } from "react-i18next";
import { useBolumEkle } from "../hooks/useBolum";
import { useGetUserInfo } from "../hooks/useGetUserInfo";

const { Content } = Layout;

function BolumEkle() {
    const { t, i18n } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const { bolumEkle } = useBolumEkle();
    const { firmaID } = useGetUserInfo();

    const onSubmit = (e) => {
        console.log("firmaID : ", firmaID);
        bolumEkle({
            bolumIsmi: e.bolumIsmi,
            firmaID: firmaID,
        });
    };

    return (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <Form
                    id="bolumEkleForm"
                    autoComplete="off"
                    layout="vertical"
                    style={{ margin: 30 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ");
                        console.log(error);
                    }}
                >
                    {contextHolder}
                    <Form.Item
                        name="bolumIsmi"
                        label={t("bolum.bolumIsmi")}
                        rules={[{ required: true, message: t("genel.gerekli") }, { whitespace: true }]}
                        hasFeedback
                    >
                        <Input placeholder={t("bolum.bolumIsmiHint")} />
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
}

export default BolumEkle;
