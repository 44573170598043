import { addDoc, collection, deleteDoc, doc, getDoc, onSnapshot, query, updateDoc } from "firebase/firestore";
import { db } from "../firebase/config";
import { useEffect, useState } from "react";

export const useRolEkle = () => {
    const roleCollectionRef = collection(db, "roles");
    const rolEkle = async ({ name, yetkiler }) => {
        await addDoc(roleCollectionRef, {
            name,
            yetkiler,
        });
    };

    return { rolEkle };
};

export const useRolGuncelle = (id, e) => {
    const rolGuncelle = async ({ id, e }) => {
        console.log("update Role : ", e);
        await updateDoc(
            doc(db, "roles", id),
            {
                name: e.name ? e.name : "",
                yetkiler: e.yetkiler ? e.yetkiler : "",
            },
            { merge: true }
        );
    };
    return { rolGuncelle };
};

export const useRolSil = (id) => {
    const rolSil = async () => {
        await deleteDoc(doc(db, "roles", id));
    };
    return { rolSil };
};

export const useRolleriGetir = () => {
    const [roller, setRoller] = useState([]);
    const roleCollectionRef = collection(db, "roles");

    const rolleriGetir = async () => {
        let unsubscribe;
        try {
            const queryRoles = query(roleCollectionRef);
            unsubscribe = onSnapshot(queryRoles, (snapshot) => {
                let docs = [];
                snapshot.forEach((doc) => {
                    const data = doc.data();
                    const id = doc.id;
                    docs.push({ ...data, id });
                });
                setRoller(docs);
            });
        } catch (err) {
            console.error(err);
        }
        return () => unsubscribe();
    };

    useEffect(() => {
        rolleriGetir();
    }, []);

    return { roller };
};

export const useRoluGetir = (id) => {
    const [rol, setRol] = useState([]);
    const roluGetir = async () => {
        if (id) {
            try {
                const docRef = doc(db, "roles", id);
                const snapshot = await getDoc(docRef);
                const data = snapshot.data();
                setRol(data);
            } catch (err) {
                console.error(err);
            }
        }
        return () => rol;
    };

    useEffect(() => {
        roluGetir();
    }, []);

    return { rol };
};
