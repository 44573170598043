import ulkeililce from "../constant/ulkeililce.json";

import { Form, Cascader, Input, Layout, notification, Space, Tooltip } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFirmaEkle } from "../hooks/useFirma";

const { Header, Content } = Layout;

function FirmaEkle() {
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const { firmaEkle } = useFirmaEkle();
    const [regkey, setRegkey] = useState(null);

    const onSubmit = (e) => {
        const ulke = e.ulkeililce[0];
        const il = e.ulkeililce[1];
        const ilce = e.ulkeililce[2];
        let cusTitle = e.firmaUnvani.toUpperCase();
        firmaEkle({
            firmaismi: e.firmaismi,
            firmaUnvani: cusTitle,
            ulke,
            il,
            ilce,
            vergiNo: e.vergiNo,
        });
    };

    const onChange = (text) => {
        setRegkey(text);
    };

    const sharedProps = {
        onChange,
    };

    return (
        <Layout style={{ margin: 0, padding: 0 }}>
            <Content style={{ padding: "0px" }}>
                <Form
                    id="companyAddForm"
                    layout="vertical"
                    autoComplete="off"
                    style={{ margin: 30 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ", error);
                    }}
                >
                    {contextHolder}
                    <Form.Item name="vergiNo" label={t("firma.vergiNo")} rules={[{ required: true, message: t("genel.gerekli") }]}>
                        <Input placeholder={t("firma.vergiNoHint")} maxLength={15} />
                    </Form.Item>
                    <Form.Item
                        name="firmaismi"
                        label={t("firma.firmaismi")}
                        rules={[{ required: true, message: t("genel.gerekli") }, { whitespace: true }]}
                        style={{ marginTop: 20 }}
                    >
                        <Input placeholder={t("firma.firmaismi")} maxLength={255} />
                    </Form.Item>
                    <Form.Item name="firmaUnvani" label={t("firma.firmaUnvani")} rules={[{ required: true, message: t("genel.gerekli") }]}>
                        <Input placeholder={t("firma.firmaUnvaniHint")} maxLength={255} style={{ textTransform: "uppercase" }} />
                    </Form.Item>
                    <Form.Item name="ulkeililce" label={t("firma.ulkeililce")} rules={[{ required: true, message: t("genel.gerekli") }]}>
                        <Cascader options={ulkeililce} placeholder={t("genel.seciniz")} />
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
}

export default FirmaEkle;
