import { Card, Col, Form, Button, Input, notification, Row, Space, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { auth } from "../firebase/config";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    signInWithPopup,
    GoogleAuthProvider,
} from "firebase/auth";
import { GoogleOutlined } from "@ant-design/icons";
import { useKisiOlustur } from "../hooks/useKisi";
import Languages from "./Languages";

function Login() {
    const { t } = useTranslation();
    const { kisiOlustur } = useKisiOlustur();
    const [errMessage, setErrMessage] = useState(null);
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const [kisiID, setkisiID] = useState();
    const [firmaID, setfirmaID] = useState();
    const [firmaismi, setfirmaismi] = useState();
    const [firmaLogo, setFirmaLogo] = useState();
    const [kisiIsmi, setKisiIsmi] = useState();
    const [kisiFoto, setKisiFoto] = useState();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    async function kisiKaydiYarat(user) {
        fetch("http://89.19.24.18:3001/Login", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                uid: user.uid,
                isim: user.displayName,
                email: user.email,
                fotoURL: user.imageURL,
            }),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("usekisiolustur : ", data);
                const authInfo = {
                    kisiID: data["kisiID"],
                    adSoyad: `${data["isim"]} ${data["soyad"]}`,
                    profilePhoto: data["fotoURL"],
                    firmaID: data["firmaID"],
                    firmaismi: data["firmaismi"],
                    firmaLogo: data["firmaLogo"],
                    yetkiler: data["yetkiler"],
                };
                localStorage.setItem("auth", JSON.stringify(authInfo));
                const accessToken = JSON.parse(localStorage.getItem("kmyToken")) || {};

                fetch(`http://89.19.24.18:3001/Kisi/byUID/${user.uid}`, {
                    headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (data) {
                        console.log("personData : ", data);
                        if (data) {
                            console.log("girdi");
                            setkisiID(data["kisiID"]);
                            setKisiIsmi(data["isim"]);
                            setKisiFoto(data["fotoURL"]);
                            if (data["firmaID"] !== "") {
                                fetch(`http://89.19.24.18:3001/Firma/byId/${data["firmaID"]}`, {
                                    headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
                                })
                                    .then(function (response) {
                                        return response.json();
                                    })
                                    .then(function (companyData) {
                                        setfirmaismi(companyData["firmaismi"]);
                                        setFirmaLogo(companyData["logoURL"]);
                                        setfirmaID(companyData["firmaID"]);
                                    });
                            } else {
                                setfirmaID("");
                            }
                        }
                    });
            });
    }

    useEffect(() => {
        const authInfo = {
            kisiID: kisiID,
            firmaID: firmaID,
            firmaismi: firmaismi,
            firmaLogo: firmaLogo,
            adSoyad: kisiIsmi,
            profilePhoto: kisiFoto ? kisiFoto : "",
            permissions: "",
        };
        localStorage.setItem("auth", JSON.stringify(authInfo));

        if (firmaID !== undefined) {
            if (firmaID === "") {
                navigate(`/kisiFirma/${kisiID}`);
            } else {
                navigate("/");
            }
        }
    }, [firmaID]);

    function handleSignup(e) {
        e.preventDefault();
        form.validateFields(["username", "password"])
            .then(() => {
                createUserWithEmailAndPassword(auth, form.getFieldValue(["username"]), form.getFieldValue(["password"]))
                    .then((userCredential) => {
                        kisiKaydiYarat(userCredential.user);
                        api["success"]({ message: t("login.userCreated"), placement: "topRight" });
                        navigate(`/`);
                    })
                    .catch((error) => {
                        console.log("handleSignup error : ", error);
                        setErrMessage(t(`errors.${error.code}`));
                    });
            })
            .catch((errors) => {
                console.error("catch:" + errors);
            });
    }

    function handleLogin(e) {
        e.preventDefault();
        form.validateFields(["username", "password"])
            .then(() => {
                signInWithEmailAndPassword(auth, form.getFieldValue(["username"]), form.getFieldValue(["password"]))
                    .then((userCredential) => {
                        kisiKaydiYarat(userCredential.user);
                    })
                    .catch((error) => {
                        // auth/invalid-email
                        // auth/email-already-in-use
                        setErrMessage(t(error.code));
                    });
            })
            .catch((errors) => {
                console.error("catch:", errors);
            });
    }

    const googleLogin = async () => {
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);
        kisiKaydiYarat(result.user);
    };

    function handlePasswordReset() {
        form.validateFields(["username"])
            .then(() => {
                sendPasswordResetEmail(auth, form.getFieldValue("username"))
                    .then((e) => {
                        api["success"]({ message: t("login.resetMailSent"), placement: "topRight" });
                    })
                    .catch((error) => {
                        // auth/invalid-email
                        // auth/email-already-in-use
                        setErrMessage(t(error.code));
                    });
            })
            .catch((errors) => {
                console.error("catch:", errors);
            });
    }

    return (
        <>
            <div style={{ position: "absolute", right: 50, top: 30 }}>
                <Languages caller="login" />
            </div>
            <Row
                style={{
                    height: 320,
                    background: "#505050",
                    justifyContent: "center",
                }}
            >
                <Col style={{ top: "50%", alignItems: "center" }}>
                    <Card
                        hoverable
                        style={{
                            width: 500,
                            textAlign: "center",
                            backgroundColor: "#EFEFEF",
                            bordered: true,
                        }}
                        cover={
                            <div style={{ overflow: "hidden", height: "160px", backgroundColor: "#505050" }}>
                                <img src="http://pro.kmysistem.com/images/kmy_Logo.png" style={{ height: "80%", margin: 10 }} />
                            </div>
                        }
                    >
                        {contextHolder}
                        <Form
                            form={form}
                            autoComplete="off"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 14 }}
                            onFinish={(values) => {
                                handleLogin(values);
                            }}
                            onFinishFailed={(error) => {
                                console.error("onFinishFailed : ", error);
                            }}
                            style={{ marginTop: 10 }}
                        >
                            <Form.Item name="username" label={t("login.username")} rules={[{ required: true, message: t("genel.gerekli") }]}>
                                <Input
                                    placeholder={t("login.usernameHint")}
                                    maxLength={60}
                                    autoComplete="username"
                                    onChange={() => setErrMessage(null)}
                                />
                            </Form.Item>

                            <Form.Item name="password" label={t("login.password")} rules={[{ required: true, message: t("genel.gerekli") }]}>
                                <Input.Password
                                    placeholder={t("login.passwordHint")}
                                    maxLength={16}
                                    autoComplete="current-password"
                                    onChange={() => setErrMessage(null)}
                                />
                            </Form.Item>

                            <Space direction="horizontal" style={{ width: "100%", justifyContent: "center", marginTop: 20, marginBottom: 20 }}>
                                <Button block type="primary" onClick={(e) => handleLogin(e)} danger={errMessage}>
                                    {t("login.login")}
                                </Button>
                                <Button block type="primary" onClick={(e) => handleSignup(e)} danger={errMessage}>
                                    {t("login.signup")}
                                </Button>
                            </Space>
                            <Space direction="horizontal" style={{ width: "100%", justifyContent: "center", marginTop: 20, marginBottom: 20 }}>
                                <Tooltip title={t("login.withGoogle")}>
                                    <Button size="large" icon={<GoogleOutlined />} onClick={(e) => googleLogin(e)} />
                                </Tooltip>
                            </Space>
                            <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                <Button
                                    type="link"
                                    onClick={() => {
                                        handlePasswordReset();
                                    }}
                                >
                                    {t("login.forgotPassword")}
                                </Button>
                            </Space>
                            {errMessage && <div>{t(errMessage)}</div>}
                        </Form>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default Login;
