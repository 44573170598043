import { Button, message, Layout, notification, Space, Upload } from "antd";
import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import { useKisiEkle } from "../hooks/useKisi";
import { useGetUserInfo } from "../hooks/useGetUserInfo";
import * as XLSX from "xlsx";
import { LoadingOutlined, ImportOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useOgrenciEkle } from "../hooks/useEgitim";

const { Content } = Layout;

function KisiAktar(props) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const { kisiID, firmaID } = useGetUserInfo();
    const [loading, setLoading] = useState(false);
    const [excelData, setExcelData] = useState();
    const [toplamKisi, setToplamKisi] = useState(0);
    const { kisiEkle } = useKisiEkle();
    const { ogrenciEkle } = useOgrenciEkle(props.egitimID);

    const handleUploadChange = (info) => {
        if (info.file.status === "uploading") {
            //setLoading(true);
            return;
        }
        if (info.file.status === "done") {
            setLoading(false);
            console.log("image upload done");
        }
    };

    const fileUpload = async ({ onError, onSuccess, file }) => {
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            const { result } = event.target;
            const workbook = XLSX.read(result, { type: "binary" });
            for (const Sheet in workbook.Sheets) {
                XLSX.utils.sheet_to_row_object_array(workbook.Sheets[0]);
                if (workbook.Sheets.hasOwnProperty(Sheet)) {
                    const parsedData = XLSX.utils.sheet_to_json(workbook.Sheets[Sheet]);

                    var excelTable = [];
                    var counter = 0;
                    parsedData.map(async (dataRow, index) => {
                        var rowMail = dataRow["Mail"] ? dataRow["Mail"] : "";
                        var rowTC = dataRow["TC"] ? dataRow["TC"] : "";
                        var rowTel = dataRow["Telefon"] ? `${dataRow["Telefon"]}` : "";
                        var rowAd = dataRow["Ad"] ? dataRow["Ad"] : "";
                        var rowSoyad = dataRow["Soyad"] ? dataRow["Soyad"] : "";
                        rowMail = rowMail.trim();
                        rowAd = sentenceCase(rowAd.trim());
                        rowSoyad = rowSoyad.trim().toUpperCase();
                        if (typeof rowTel === "string") {
                            rowTel = rowTel.replace(/\D/g, "");
                            if (rowTel.length === 10) {
                                rowTel = `+90${rowTel}`;
                            } else if (rowTel.length === 11 && rowTel.charAt[0] === "0") {
                                rowTel = `+9${rowTel}`;
                            }
                        }
                        counter++;
                        console.log("kisiEkle : ", rowAd, rowSoyad);
                        var kisiPK = "";

                        fetch(`http://89.19.24.18:3001/Kisi/`, {
                            method: "PUT",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                                isim: rowAd,
                                soyad: rowSoyad,
                                email: rowMail,
                                telefonNo: rowTel,
                                TCno: rowTC,
                                firmaID: "",
                            }),
                        })
                            .then(function (response) {
                                return response.json();
                            })
                            .then(function (data) {
                                kisiPK = data.body;
                                const tableRow = { id: kisiPK, mail: rowMail, tc: rowTC, tel: rowTel, ad: rowAd, soyad: rowSoyad };
                                const e = {
                                    kisiID: kisiPK,
                                    isim: rowAd,
                                    soyad: rowSoyad,
                                    firmaID: "",
                                };
                                ogrenciEkle({ id: props.egitimID, e });
                                excelTable.push(tableRow);
                                setToplamKisi(counter);
                                setExcelData(excelTable);
                            });
                    });
                }
            }
            message.success("upload success!");
        };
        fileReader.readAsBinaryString(file);
        setLoading(false);
    };

    function sentenceCase(str) {
        if (str === null || str === "") return false;
        else str = str.toString().toLowerCase();

        return str
            .toLowerCase()
            .split(" ")
            .map(function (word) {
                return word.charAt(0).toUpperCase().concat(word.substr(1));
            })
            .join(" ");
    }

    return (
        <Layout>
            <Content>
                <Space style={{ width: "100%", justifyContent: "center", padding: 20 }}>
                    {excelData === undefined && (
                        <Upload
                            name="excelFile"
                            accept=".xls, .xlsx"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={fileUpload}
                            onChange={handleUploadChange}
                        >
                            <Button type="dashed" icon={loading ? <LoadingOutlined /> : <ImportOutlined />}>
                                {t("genel.dosyaSec")}
                            </Button>
                        </Upload>
                    )}
                </Space>
                {excelData === undefined && <div>Excel dosyası sırasıyla "Mail", "TC", "Telefon", "Ad" ve "Soyad" sütunlarını içermelidir</div>}
                {excelData !== undefined && (
                    <>
                        <Space style={{ width: "100%", justifyContent: "center", padding: 20 }}>
                            {toplamKisi > 0 && `Excel dosyasındaki ${toplamKisi} kişi sınıf mevcuduna eklendi.`}
                        </Space>
                    </>
                )}
            </Content>
        </Layout>
    );
}

export default KisiAktar;
