import { React, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Space } from "antd";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

function ChangePassword() {
    const { Meta } = Card;
    const { state } = useLocation();
    const { otp, mail } = state || {};
    const { t } = useTranslation();
    const [isPending, setIsPending] = useState(false);
    const [errMessage, setErrMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [min8char, setMin8char] = useState(false);
    const [min1upper, setMin1upper] = useState(false);
    const [min1digit, setMin1digit] = useState(false);
    const [min1special, setMin1special] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    console.log("OTP:" + otp + " mail:" + mail);

    const onSubmit = (e) => {
        let pinCode = form.getFieldValue(["resetCode"]);
        console.log(pinCode);
        if (pinCode == otp) {
        } else {
            setErrMessage("hatalı pin kodu");
        }
    };
    /*
^               Start anchor
(?=.*[A-Z])     Ensure string has one uppercase letters.
(?=.*[!@#$&*])  Ensure string has one special case letter.
(?=.*[0-9])     Ensure string has one digits.
(?=.*[a-z])     Ensure string has one lowercase letters.
.{8}            Ensure string is of length 8.
$               End anchor.

*/

    return (
        <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
            <Col height="100%" justify="center">
                <Row justify="center" style={{ textAlign: "center", marginBottom: 20 }}>
                    <Trans i18nKey="genel.resetDesc" values={{ br: "br" }} components={{ br: <br /> }} />
                </Row>
                <Card
                    style={{ width: 500, backgroundColor: "#FAFAFF" }}
                    cover={
                        <div style={{ overflow: "hidden", height: "200px", backgroundColor: "#505050" }}>
                            <img src="http://pro.kmysistem.com/images/kmy_Logo.png" style={{ height: "80%", margin: 20 }} />
                        </div>
                    }
                >
                    <Form
                        form={form}
                        autoComplete="off"
                        labelCol={{ span: 9 }}
                        wrapperCol={{ span: 15 }}
                        onFinish={(values) => onSubmit(values)}
                        onFinishFailed={(error) => {
                            console.log({ error });
                        }}
                    >
                        <Form.Item
                            name="resetCode"
                            label={t("genel.resetCode")}
                            rules={[
                                { required: true, message: t("genel.gerekli") },
                                { min: 4, message: t("genel.tooShort") },
                            ]}
                        >
                            <Input placeholder="****" maxLength={4} autoComplete="otp" />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            label={t("login.password")}
                            rules={[
                                { required: true, message: t("genel.gerekli") },
                                { min: 8, message: t("genel.min8") },
                                {
                                    pattern: new RegExp("^(?=.*[a-z]).{1,}$"),
                                    message: t("genel.lower"),
                                    textAlign: "left",
                                },
                                {
                                    pattern: new RegExp("^(?=.*[A-Z]).{1,}$"),
                                    message: t("genel.upper"),
                                },
                                {
                                    pattern: new RegExp("^(?=.*[0-9]).{1,}$"),
                                    message: t("genel.digit"),
                                },
                                {
                                    pattern: new RegExp("^(?=.*[!+*/%&=?]).{1,}$"),
                                    message: t("genel.symbol"),
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password placeholder={t("login.passwordHint")} maxLength={16} autoComplete="new-password" />
                        </Form.Item>

                        <Form.Item
                            name="confirmPassword"
                            label={t("login.passwordAgain")}
                            dependencies={["password"]}
                            rules={[
                                { required: true, message: t("genel.gerekli") },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue("password") === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(t("genel.notEqual"));
                                    },
                                }),
                            ]}
                            hasFeedback
                        >
                            <Input.Password placeholder={t("login.passwordAgainHint")} maxLength={16} autoComplete="new-password" />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 10, span: 6 }}>
                            <Button block type="primary" htmlType="submit" loading={isPending} danger={errMessage}>
                                {t("genel.kaydet")}
                            </Button>
                        </Form.Item>
                        {errMessage && <div>{t(errMessage)}</div>}
                    </Form>
                </Card>
            </Col>
        </Space>
    );
}

export default ChangePassword;
