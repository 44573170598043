import { Form, Input, Layout } from "antd";
import { React } from "react";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import { useEgitimEkle } from "../hooks/useEgitim";
import { useGetUserInfo } from "../hooks/useGetUserInfo";

const { Content } = Layout;

function EgitimEkle() {
    const { t } = useTranslation();
    const { egitimEkle } = useEgitimEkle();
    const { kisiID, firmaID } = useGetUserInfo();

    const onSubmit = (e) => {
        egitimEkle({
            firmaID: firmaID,
            egitimIsmi: e.egitimIsmi,
        });
    };

    return (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <Form
                    id="egitimEkleForm"
                    autoComplete="off"
                    layout="vertical"
                    style={{ margin: 30 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ");
                        console.log(error);
                    }}
                >
                    <Form.Item
                        name="egitimIsmi"
                        label={t("training.egitimIsmi")}
                        rules={[{ required: true, message: t("genel.gerekli") }, { whitespace: true }]}
                    >
                        <Input placeholder={t("training.egitimIsmiHint")} />
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
}

export default EgitimEkle;
