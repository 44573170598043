import { useEffect, useState } from "react";
import { useGetToken } from "./useGetToken";

export const useBelgeEkle = (id, sahipTipi) => {
    const { accessToken } = useGetToken();
    const belgeEkle = async ({ e }) => {
        //! DOiT
    };
    return { belgeEkle };
};

export const useBelgeleriGetir = (id, sahipTipi) => {
    const { accessToken } = useGetToken();
    const [belgeler, setBelgeler] = useState();
    const belgeleriGetir = async () => {
        fetch(`http://89.19.24.18:3001/Belge/${id}/${sahipTipi}`, {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setBelgeler(data);
            });

        return () => belgeler();
    };

    useEffect(() => {
        belgeleriGetir();
    }, []);

    return { belgeler };
};

export const useBelgeTipleriniGetir = (id, sahipTipi) => {
    const { accessToken } = useGetToken();
    const [belgeTipleri, setBelgeTipleri] = useState();
    const belgeTipleriniGetir = async () => {
        fetch(`http://89.19.24.18:3001/Belge/types/${id}/${sahipTipi}`, {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setBelgeTipleri(data);
            });

        return () => belgeTipleri();
    };

    useEffect(() => {
        belgeTipleriniGetir();
    }, []);

    return { belgeTipleri };
};
/* 
export const useUpdateDocument = (id, e) => {
    const updateDocument = async ({ id, e }) => {
        fetch(`http://89.19.24.18:3001/Documents/byId/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("update cevap : ", data);
            });
    };
    return { updateDocument };
};
 */
