import { Form, Button, DatePicker, Input, Layout, message, notification, Select, Upload } from "antd";
import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import locale from "antd/es/date-picker/locale/tr_TR";
import { UploadOutlined } from "@ant-design/icons";
import { useGetUserInfo } from "../hooks/useGetUserInfo";

import { v4 } from "uuid";
const { Header, Content } = Layout;

function BelgeEkle(docProps) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const [docbelgeTipiID, setDocbelgeTipiID] = useState();
    const [docDesc, setDocDesc] = useState();
    const [gecerlikTarihi, setgecerlikTarihi] = useState();
    const [fileList, setFileList] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const { firmaID } = useGetUserInfo();

    const docTypeList = docProps.docTypeList;

    const beforeUpload = (file) => {
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error(t("genel.lessThan10"));
        }
        return isLt10M;
    };

    const onSubmit = (e) => {
        fileList.forEach((file) => {
            const formData = new FormData();
            const parts = file.name.split(".");
            const fileExt = parts[parts.length - 1];

            formData.append("doc", file, `file_${v4()}.${fileExt}`);
            formData.append("belgeTipiID", docbelgeTipiID);
            formData.append("belgeIsmi", docDesc);
            formData.append("gecerlikTarihi", gecerlikTarihi);
            formData.append("firmaID", firmaID);
            const createXHR = () => new XMLHttpRequest();
            fetch(`http://89.19.24.18:3001/Belge/${docProps.owner}/${docProps.id}`, {
                body: formData,
                createXHR,
                method: "POST",
            })
                .then((response) => {
                    return response.json();
                })
                .then((res) => {
                    console.log(res, "res");
                });
        });

        setLoading(false);
    };

    const props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };

    function onSelectDate(date, dateString) {
        console.log(date, dateString);
        setgecerlikTarihi(dateString);
    }

    return (
        <Layout style={{ padding: 30 }}>
            <Content style={{ padding: "0px" }}>
                <Form
                    name="docAddForm"
                    autoComplete="off"
                    layout="vertical"
                    style={{ margin: 30 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ");
                        console.log(error);
                    }}
                >
                    {contextHolder}
                    <Form.Item name="belgeTipi" label={t("belge.belgeTipi")} rules={[{ required: true, message: t("genel.gerekli") }]}>
                        <Select placeholder={t("genel.seciniz")} onChange={(val) => setDocbelgeTipiID(val)}>
                            {docProps.typeList === undefined
                                ? ""
                                : docProps.typeList.map((item, index) => (
                                      <Select.Option value={item.id} key={index}>
                                          {item.belgeTipIsmi}
                                      </Select.Option>
                                  ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="belgeIsmi" label={t("belge.belgeIsmi")} maxLength={255}>
                        <Input placeholder={t("belge.belgeIsmiHint")} onChange={(e) => setDocDesc(e.target.value)} />
                    </Form.Item>
                    <Form.Item name="gecerlikTarihi" label={t("belge.gecerlikTarihi")}>
                        <DatePicker style={{ width: "100%" }} locale={locale} placeholder={t("belge.gecerlikTarihi")} onChange={onSelectDate} />
                    </Form.Item>
                    <Form.Item label={t("belge.theFile")}>
                        <Upload {...props}>
                            <Button icon={<UploadOutlined />} disabled={fileList.length > 0}>
                                {t("belge.selectFile")}
                            </Button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
}

export default BelgeEkle;
