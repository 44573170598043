import { Avatar, Card, Layout, notification, Row, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { auth } from "../firebase/config";
import { onAuthStateChanged } from "firebase/auth";
import { ExperimentOutlined, MailOutlined, ShopOutlined, TeamOutlined, WarningOutlined } from "@ant-design/icons";
import { useGetUserInfo } from "../hooks/useGetUserInfo";
import CollectionsBookmarkOutlinedIcon from "@mui/icons-material/CollectionsBookmarkOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import KmyHeader from "./KmyHeader";

const { Meta } = Card;
const { Header, Content } = Layout;

function Home() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const [user, setUser] = useState({});
    const { adSoyad, kisiID, profilePhoto, firmaLogo, firmaismi } = useGetUserInfo();

    useEffect(() => {
        onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser === null) {
                navigate("/login");
                localStorage.removeItem("auth");
                localStorage.removeItem("kmyToken");
            }
        });
    }, []);

    const kisiResmi = (
        <Tooltip title={adSoyad}>
            <Avatar
                key="kisiAvatar"
                src={profilePhoto && `${profilePhoto}`}
                alt={adSoyad}
                referrerPolicy="no-referrer"
                style={{ marginRight: -10 }}
            ></Avatar>
        </Tooltip>
    );

    const companyImage = (
        <Tooltip key="firmaismiToolkit" title={firmaismi}>
            <Avatar
                key="companyAvatar"
                src={firmaLogo && `${firmaLogo}`}
                alt={firmaismi}
                shape="square"
                style={{ height: 36, width: 108, borderRadius: 0 }}
                referrerPolicy="no-referrer"
            ></Avatar>
        </Tooltip>
    );

    return (
        <Layout key="homeLayout">
            <KmyHeader key="homeHeader" caller="home" buttons={[kisiResmi, companyImage]} />
            <Content>
                {contextHolder}
                <Row style={{ padding: 20 }}>
                    <Card
                        hoverable
                        style={{ width: 240, margin: 5 }}
                        onClick={() => {
                            navigate("/kisiList");
                        }}
                    >
                        <Meta avatar={<TeamOutlined style={{ fontSize: 24 }} />} title={t("kisi.kisi")} />
                    </Card>
                    <Card
                        hoverable
                        style={{ width: 240, margin: 5 }}
                        onClick={() => {
                            navigate("/firmaList");
                        }}
                    >
                        <Meta avatar={<ShopOutlined style={{ fontSize: 24 }} />} title={t("firma.firma")} />
                    </Card>
                    <Card
                        hoverable
                        style={{ width: 240, margin: 5 }}
                        onClick={() => {
                            navigate("/kursList");
                        }}
                    >
                        <Meta avatar={<CollectionsBookmarkOutlinedIcon style={{ fontSize: 24 }} />} title={t("sayfaBaslik.kursList")} />
                    </Card>
                    <Card
                        hoverable
                        style={{ width: 240, margin: 5 }}
                        onClick={() => {
                            navigate("/egitimList");
                        }}
                    >
                        <Meta avatar={<SchoolOutlinedIcon style={{ fontSize: 24 }} />} title={t("sayfaBaslik.trainingList")} />
                    </Card>
                    <Card
                        hoverable
                        style={{ width: 240, margin: 5 }}
                        onClick={() => {
                            navigate("/tableA");
                        }}
                    >
                        <Meta avatar={<ExperimentOutlined style={{ fontSize: 24 }} />} title={t("dg.dg")} />
                    </Card>
                    <Card
                        hoverable
                        style={{ width: 240, margin: 5 }}
                        onClick={() => {
                            navigate("/riskList");
                        }}
                    >
                        <Meta avatar={<WarningOutlined style={{ fontSize: 24 }} />} title={t("sayfaBaslik.riskAnalizi")} />
                    </Card>
                    <Card
                        hoverable
                        style={{ width: 240, margin: 5 }}
                        onClick={() => {
                            navigate("/bildirimList");
                        }}
                    >
                        <Meta avatar={<MailOutlined style={{ fontSize: 24 }} />} title={t("sayfaBaslik.bildirim")} />
                    </Card>
                </Row>
            </Content>
        </Layout>
    );
}

export default Home;

/*
                    <Card
                        hoverable
                        style={{ width: 240, margin: 5 }}
                        onClick={() => {
                            navigate("/roleList");
                        }}
                    >
                        <Meta avatar={<UnlockOutlined style={{ fontSize: 24 }} />} title={t("Kullanıcı Rolleri")} />
                    </Card>
*/
