import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAkUm02cUhALPIPzFf9p54ag-ghaXB9Lk0",
    authDomain: "kmy-sistem.firebaseapp.com",
    projectId: "kmy-sistem",
    storageBucket: "kmy-sistem.appspot.com",
    messagingSenderId: "43400242288",
    appId: "1:43400242288:web:7182060fbc9b5b3d4540b5",
    measurementId: "G-70M7QT6NQP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const db = getFirestore(app);
export const storage = getStorage(app);
