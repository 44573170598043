import csgbSubject from "../constant/csgbSubject.json";
import { Cascader, Form, Input, Layout, notification } from "antd";
import { React } from "react";
import { useTranslation } from "react-i18next";
import { useRolEkle } from "../hooks/useRol";

const { Content } = Layout;
const { TextArea } = Input;

function RolEkle() {
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const { rolEkle } = useRolEkle();

    const onSubmit = (e) => {
        rolEkle({
            rolIsmi: e.rolIsmi,
            yetkiler: "",
        });
    };

    return (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <Form
                    id="rolEkleForm"
                    autoComplete="off"
                    layout="vertical"
                    style={{ margin: 30 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                >
                    <Form.Item
                        name="rolIsmi"
                        label={t("rol.rolIsmi")}
                        rules={[{ required: true, message: t("genel.gerekli") }, { whitespace: true }]}
                    >
                        <Input placeholder={t("rol.rolIsmiHint")} />
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
}

export default RolEkle;
