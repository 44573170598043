import { useEffect, useState } from "react";
import { useGetToken } from "./useGetToken";

export const useKursEkle = () => {
    const { accessToken } = useGetToken();
    const kursEkle = async ({ kursIsmi }) => {
        fetch(`http://89.19.24.18:3001/Kurs/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify({
                kursIsmi: kursIsmi ? kursIsmi : "",
                kursdetayi: "",
                kategori: "",
                resimURL: "",
                csgbGrup: "",
                csgbAltGrup: "",
            }),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("add cevap : ", data);
            });
    };

    return { kursEkle };
};

export const useKursGuncelle = (id, e) => {
    const { accessToken } = useGetToken();
    const kursGuncelle = async ({ id, e }) => {
        fetch(`http://89.19.24.18:3001/Kurs/byId/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("update cevap : ", data);
            });
    };
    return { kursGuncelle };
};

export const useKursSil = (id) => {
    const { accessToken } = useGetToken();
    const kursSil = async () => {
        fetch(`http://89.19.24.18:3001/Kurs/byId/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("delete cevap : ", data);
            });
    };
    return { kursSil };
};

export const useKurslariGetir = () => {
    const { accessToken } = useGetToken();
    const [kurslar, setKurslar] = useState([]);

    const kurslariGetir = async () => {
        fetch("http://89.19.24.18:3001/Kurs", {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setKurslar(data);
            });
        return () => kurslar;
    };

    useEffect(() => {
        kurslariGetir();
    }, []);

    return { kurslar };
};

export const useKursuGetir = (id) => {
    const { accessToken } = useGetToken();
    const [kurs, setKurs] = useState([]);
    const kursuGetir = async () => {
        if (id) {
            fetch(`http://89.19.24.18:3001/Kurs/byId/${id}`, {
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    setKurs(data);
                });
        }
        return () => kurs;
    };

    useEffect(() => {
        kursuGetir();
    }, []);

    return { kurs };
};
