import { Avatar, Button, Image, Input, Layout, Modal, notification, Space, Spin, Table } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SearchOutlined, EditOutlined } from "@ant-design/icons";
import KmyHeader from "./KmyHeader";
import KursEkle from "./KursEkle";
import { useKurslariGetir } from "../hooks/useKurs";
const { Header, Content } = Layout;

function KursList() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [filteredSource, setFilteredSource] = useState([]);
    const [errMessage, setErrMessage] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const { kurslar } = useKurslariGetir();
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        setDataSource(kurslar);
        setFilteredSource(kurslar);
        setIsPending(false);
    }, [kurslar]);

    const ekleButon = (
        <Button
            key="ekleButon"
            type="primary"
            htmlType="submit"
            loading={isPending}
            danger={errMessage}
            size="small"
            onClick={() => {
                setModalOpen(true);
            }}
        >
            {t("genel.ekle")}
        </Button>
    );
    const searchData = (searchText) => {
        setFilteredSource(dataSource);
        if (searchText) {
            setFilteredSource(
                dataSource.filter((item) => {
                    const title = item.title ? item.title.toLowerCase() : "";
                    const comparisonString = `${title}`;
                    return comparisonString.includes(searchText.toLowerCase());
                })
            );
        }
    };

    const columns = [
        {
            title: "",
            width: 40,
            key: (p) => p.kursID,
            render: (p) => (
                <Button
                    type="text"
                    key={p.kursID}
                    icon={<EditOutlined />}
                    style={{ marginRight: -15 }}
                    onClick={() => {
                        navigate(`/kursGuncelle/${p.kursID}`);
                    }}
                />
            ),
        },
        {
            title: t("kurs.kursIsmi"),
            dataIndex: "kursIsmi",
            key: (p) => p.kursID & "kursIsmi",
            sorter: (a, b) => a.kursIsmi.localeCompare(b.kursIsmi),
        },
        {
            title: t("kurs.kursdetayi"),
            dataIndex: "kursdetayi",
            render: function (html) {
                return <div dangerouslySetInnerHTML={{ __html: html }} />;
            },
            key: (p) => p.id & "kursdetayi",
            sorter: (a, b) => a.kursdetayi.localeCompare(b.kursdetayi),
        },
    ];

    const onChange = (pagination, filters, sorter, extra) => {
        console.log("params", sorter, filters);
    };

    return isPending ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <KmyHeader caller="kursList" buttons={[ekleButon]} backURL="/" />
                {contextHolder}
                <Input
                    key="searchBox"
                    placeholder={t("genel.ara")}
                    prefix={<SearchOutlined />}
                    style={{ margin: 10, width: 300 }}
                    onChange={(e) => {
                        searchData(e.target.value);
                    }}
                />
                <Table key="kursList" dataSource={filteredSource} columns={columns} onChange={onChange} />
                <Modal
                    key="modalForm"
                    open={modalOpen}
                    onOk={() => {
                        setModalOpen(false);
                    }}
                    onCancel={() => {
                        setModalOpen(false);
                    }}
                    footer={[
                        <Button
                            key="cancel"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            style={{ marginRight: 20 }}
                        >
                            {t("genel.vazgec")}
                        </Button>,
                        <Button
                            key="ok"
                            type="primary"
                            form="kursEkleForm"
                            htmlType="submit"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                        >
                            {t("genel.kaydet")}
                        </Button>,
                    ]}
                >
                    <KursEkle />
                </Modal>
            </Content>
        </Layout>
    );
}

export default KursList;
