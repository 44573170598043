import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Button, Checkbox, Collapse, Image, Layout, notification, Panel, Row, Space, Tooltip } from "antd";

const { Header, Content } = Layout;

function RolGuncelle() {
    let { id } = useParams();
    const { t } = useTranslation();
    const [isPending, setIsPending] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [permissions, setPermissions] = useState([99999]);

    const onChange = (e) => {
        const newPermissions = [...permissions];
        const permValue = parseInt(e.target.value);
        const isChecked = e.target.checked;
        if (e.target.checked) {
            if (!permissions.includes(parseInt(e.target.value))) newPermissions.push(parseInt(e.target.value));
        } else {
            const index = newPermissions.indexOf(parseInt(e.target.value));
            if (index != -1) {
                newPermissions.splice(index, 1);
            }
        }

        if (permValue === 10101 && isChecked && !permissions.includes(10100)) newPermissions.push(parseInt(10100));
        if (permValue === 10102 && isChecked && !permissions.includes(10100)) newPermissions.push(parseInt(10100));

        setPermissions(newPermissions);
    };

    //prettier-ignore
    const items = [
        {
            key: "999",
            label: t("permissions.999"),
            children: (
                <>
                    <Row style={{ paddingBottom: 5 }}><Checkbox value="99999" checked={permissions.includes(99999)} onChange={onChange}>{t("permissions.99999")}</Checkbox></Row>
                </>
            ),
        },
        {
            key: "101",
            label: t("permissions.101"),
            children: (
                <>
                    <Row style={{ paddingBottom: 5 }}><Checkbox value="10100" disabled={permissions.includes(10100) || permissions.includes(99999) ? false: true} checked={permissions.includes(10100)} onChange={onChange}>{t("permissions.10100")}</Checkbox></Row>
                    <Row style={{ paddingBottom: 5 }}><Checkbox value="10101" disabled={permissions.includes(10101) || permissions.includes(99999) ? false: true} checked={permissions.includes(10101)} onChange={onChange}>{t("permissions.10101")}</Checkbox></Row>
                    <Row style={{ paddingBottom: 5 }}><Checkbox value="10102" disabled={permissions.includes(10102) || permissions.includes(99999) ? false: true} checked={permissions.includes(10102)} onChange={onChange}>{t("permissions.10102")}</Checkbox></Row>
                    <Row style={{ paddingBottom: 5 }}><Checkbox value="10103" disabled={permissions.includes(10103) || permissions.includes(99999) ? false: true} checked={permissions.includes(10103)} onChange={onChange}>{t("permissions.10103")}</Checkbox></Row>
                    <Row style={{ paddingBottom: 5 }}><Checkbox value="10104" disabled={permissions.includes(10104) || permissions.includes(99999) ? false: true} checked={permissions.includes(10104)} onChange={onChange}>{t("permissions.10104")}</Checkbox></Row>
                    <Row style={{ paddingBottom: 5 }}><Checkbox value="10105" disabled={permissions.includes(10105) || permissions.includes(99999) ? false: true} checked={permissions.includes(10105)} onChange={onChange}>{t("permissions.10105")}</Checkbox></Row>
                </>
            ),
        },
        {
            key: "102",
            label: t("permissions.102"),
            children: (
                <>
                    <Row style={{ paddingBottom: 5 }}><Checkbox value="10200" disabled={permissions.includes(10200) || permissions.includes(99999) ? false: true} checked={permissions.includes(10200)} onChange={onChange}>{t("permissions.10200")}</Checkbox></Row>
                    <Row style={{ paddingBottom: 5 }}><Checkbox value="10201" disabled={permissions.includes(10201) || permissions.includes(99999) ? false: true} checked={permissions.includes(10201)} onChange={onChange}>{t("permissions.10201")}</Checkbox></Row>
                    <Row style={{ paddingBottom: 5 }}><Checkbox value="10202" disabled={permissions.includes(10202) || permissions.includes(99999) ? false: true} checked={permissions.includes(10202)} onChange={onChange}>{t("permissions.10202")}</Checkbox></Row>
                    <Row style={{ paddingBottom: 5 }}><Checkbox value="10203" disabled={permissions.includes(10203) || permissions.includes(99999) ? false: true} checked={permissions.includes(10203)} onChange={onChange}>{t("permissions.10203")}</Checkbox></Row>
                    <Row style={{ paddingBottom: 5 }}><Checkbox value="10204" disabled={permissions.includes(10204) || permissions.includes(99999) ? false: true} checked={permissions.includes(10204)} onChange={onChange}>{t("permissions.10204")}</Checkbox></Row>
                    <Row style={{ paddingBottom: 5 }}><Checkbox value="10205" disabled={permissions.includes(10205) || permissions.includes(99999) ? false: true} checked={permissions.includes(10205)} onChange={onChange}>{t("permissions.10205")}</Checkbox></Row>
                </>
            ),
        },
    ];
    /*
    useEffect(() => {
        setPermissions(auth.permissions);
    }, []);
*/
    const onSubmit = (e) => {
        setIsPending(true);
        setIsPending(false);
    };

    return (
        <Form autoComplete="off" labelCol={{ span: 9 }} wrapperCol={{ span: 9 }}>
            <Collapse items={items} defaultActiveKey={["101", "102"]}></Collapse>
        </Form>
    );
}

export default RolGuncelle;
