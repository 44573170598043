import { useEffect, useState } from "react";
import { useGetToken } from "./useGetToken";

export const useEgitimEkle = () => {
    const { accessToken } = useGetToken();
    const egitimEkle = async ({ firmaID, egitimIsmi }) => {
        fetch(`http://89.19.24.18:3001/Egitim/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify({
                firmaID: firmaID,
                egitimIsmi: egitimIsmi,
            }),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("add cevap : ", data);
            });
    };

    return { egitimEkle };
};

export const useEgitimGuncelle = (id, e) => {
    const { accessToken } = useGetToken();
    const egitimGuncelle = async ({ id, e }) => {
        fetch(`http://89.19.24.18:3001/Egitim/byId/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("update cevap : ", data);
            });
    };
    return { egitimGuncelle };
};

export const useOgrenciEkle = (id, e) => {
    const { accessToken } = useGetToken();
    const ogrenciEkle = async ({ id, e }) => {
        fetch(`http://89.19.24.18:3001/Egitim/ogrenci/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("add cevap : ", data);
            });
    };
    return { ogrenciEkle };
};

export const useOgrencileriGetir = (id) => {
    const { accessToken } = useGetToken();
    const [ogrenciler, setOgrenciler] = useState([]);
    const ogrencileriGetir = async () => {
        fetch(`http://89.19.24.18:3001/Egitim/ogrenci/${id}`, {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setOgrenciler(data);
            });

        return () => ogrenciler;
    };
    useEffect(() => {
        ogrencileriGetir();
    }, []);

    return { ogrenciler };
};

export const useEgitimSil = (id) => {
    const { accessToken } = useGetToken();
    const egitimSil = async () => {
        fetch(`http://89.19.24.18:3001/Egitim/byId/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("delete cevap : ", data);
            });
    };
    return { egitimSil };
};

export const useEgitimleriGetir = () => {
    const { accessToken } = useGetToken();
    const [egitimler, setEgitimler] = useState([]);
    const egitimleriGetir = async () => {
        fetch("http://89.19.24.18:3001/Egitim", {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setEgitimler(data);
            });

        return () => egitimler;
    };
    useEffect(() => {
        egitimleriGetir();
    }, []);

    return { egitimler };
};

export const useEgitimiGetir = (id) => {
    const { accessToken } = useGetToken();
    const [egitim, setEgitim] = useState([]);
    const egitimiGetir = async () => {
        fetch(`http://89.19.24.18:3001/Egitim/byId/${id}`, {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setEgitim(data);
            });

        return () => egitim;
    };

    useEffect(() => {
        egitimiGetir();
    }, []);

    return { egitim };
};
