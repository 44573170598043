import { useGetToken } from "./useGetToken";
import { useGetUserInfo } from "./useGetUserInfo";
import { useEffect, useState } from "react";

export const useRiskEkle = () => {
    const { accessToken } = useGetToken();
    const riskEkle = async (e) => {
        fetch(`http://89.19.24.18:3001/Risk/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify({
                riskinTanimi: e.riskinTanimi,
            }),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("add cevap : ", data);
            });
    };

    return { riskEkle };
};

export const useRiskGuncelle = (id, e) => {
    const { accessToken } = useGetToken();
    const riskGuncelle = async ({ id, e }) => {
        fetch(`http://89.19.24.18:3001/Risk/byId/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("update cevap : ", data);
            });
    };
    return { riskGuncelle };
};

export const useRiskSil = (id) => {
    const { accessToken } = useGetToken();
    const riskSil = async () => {
        fetch(`http://89.19.24.18:3001/Risk/byId/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("delete cevap : ", data);
            });
    };
    return { riskSil };
};

export const useRiskleriGetir = () => {
    const { accessToken } = useGetToken();
    const [riskler, setRiskler] = useState([]);
    const riskleriGetir = async () => {
        fetch("http://89.19.24.18:3001/Risk", { headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` } })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setRiskler(data);
            });

        return () => riskler;
    };
    useEffect(() => {
        riskleriGetir();
    }, []);

    return { riskler };
};

export const useRiskiGetir = (id) => {
    const { accessToken } = useGetToken();
    const [risk, setRisk] = useState([]);
    const riskiGetir = async () => {
        fetch(`http://89.19.24.18:3001/Risk/byId/${id}`, { headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` } })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setRisk(data);
            });

        return () => risk;
    };

    useEffect(() => {
        riskiGetir();
    }, []);

    return { risk };
};
