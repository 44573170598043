import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Alert, Form, Button, Divider, Image, Input, Layout, message, notification, Popconfirm, Select, Space } from "antd";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { CalendarOutlined, MailOutlined } from "@ant-design/icons";
import { useKisiyiGetir, useKisiGuncelle } from "../hooks/useKisi";
import KmyHeader from "./KmyHeader";

const { Header, Content } = Layout;

function KisiFirma() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    let { id } = useParams();
    const { kisi } = useKisiyiGetir(id);
    const { kisiGuncelle } = useKisiGuncelle(id);

    const [form] = Form.useForm();
    const [isPending, setIsPending] = useState(false);
    const [errMessage, setErrMessage] = useState(null);
    const [api, contextHolder] = notification.useNotification();
    const [enteredRegkey, setEnteredRegkey] = useState(null);

    useEffect(() => {
        if (kisi) {
            form.setFieldsValue({
                email: kisi.email,
                telefonNo: kisi.telefonNo,
                TCno: kisi.TCno,
                isim: kisi.isim,
                soyad: kisi.soyad,
                unvan: kisi.unvan,
                dogumYili: kisi.dogumYili,
                cinsiyet: kisi.cinsiyet,
                kanGrubu: kisi.kanGrubu,
            });
        }
    }, [kisi]);

    const onSubmit = (e) => {
        setIsPending(true);
        fetch(`http://89.19.24.18:3001/Firma/byId/${enteredRegkey}`)
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("bulunan regkey : ", data);
                if (data === null) {
                    api["error"]({ message: t("Kayıt anahtarı bulunamadı"), placement: "topRight" });
                } else {
                    e.firmaID = enteredRegkey;
                    kisiGuncelle({ id, e });

                    api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
                    console.log("kisi : ", kisi);
                    const authInfo = {
                        kisiID: kisi.uid,
                        firmaID: enteredRegkey,
                        firmaismi: data["firmaismi"],
                        firmaLogo: data["logoURL"],
                        adSoyad: `${e.isim} ${e.soyad}`,
                        profilePhoto: kisi["fotoURL"] ? kisi["fotoURL"] : "",
                        isAuth: true,
                    };
                    localStorage.setItem("auth", JSON.stringify(authInfo));

                    navigate("/");
                }
            });

        setIsPending(false);
    };

    const onChange = (text) => {
        setEnteredRegkey(text);
    };

    const sharedProps = {
        onChange,
    };

    const saveButton = (
        <Button key="saveButton" type="primary" htmlType="submit" loading={isPending} danger={errMessage} size="small">
            {t("genel.kaydet")}
        </Button>
    );

    return (
        <Layout style={{ margin: 0, padding: 0 }}>
            <Content style={{ padding: "0px" }}>
                <Form
                    form={form}
                    autoComplete="off"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 11 }}
                    onFinish={(values) => onSubmit(values)}
                    onFinishFailed={(error) => {
                        console.error("onFinishFailed : " + { error });
                    }}
                >
                    <KmyHeader caller="kisiFirma" buttons={[saveButton]} backURL="/" />
                    {contextHolder}
                    <Space direction="horizontal" style={{ width: "100%", marginTop: 20, marginBottom: 20, justifyContent: "center" }}>
                        {t("your.enterRegKey")}
                    </Space>
                    <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                        <Input.OTP {...sharedProps} size="large" />
                    </Space>
                    <Space direction="horizontal" style={{ width: "100%", color: "#aaa", justifyContent: "center" }}>
                        {t("your.dontKnow")}
                    </Space>
                    <Divider></Divider>
                    <Space direction="horizontal" style={{ width: "100%", marginTop: 20, justifyContent: "center", color: "#888" }}>
                        {t("your.beGood")}
                    </Space>
                    <Form.Item name="email" label={t("your.email")} style={{ marginTop: 20 }}>
                        <Input prefix={<MailOutlined />} placeholder={t("your.email")} disabled={true} />
                    </Form.Item>
                    <Form.Item name="telefonNo" label={t("your.telefonNo")}>
                        <PhoneInput
                            defaultCountry={"TR"}
                            className={"ant-input css-dev-only-do-not-override-mzwlov input-phone-number ant-input-outlined"}
                            placeholder={t("your.telefonNoHint")}
                        />
                    </Form.Item>
                    <Form.Item
                        name="TCno"
                        label={t("your.TCno")}
                        rules={[
                            {
                                warningOnly: true,
                                validator(_, tc) {
                                    if (tc) {
                                        if (tc.length === 11 && tc[0] !== "0") {
                                            var tek = Number(tc[0]) + Number(tc[2]) + Number(tc[4]) + Number(tc[6]) + Number(tc[8]);
                                            var cift = Number(tc[1]) + Number(tc[3]) + Number(tc[5]) + Number(tc[7]);
                                            var t10 = (tek * 7 - cift) % 10;
                                            if (Number(tc[9]) === t10 && Number(tc[10]) === (cift + tek + t10) % 10) {
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject(t("kisi.invalidTckn"));
                                            }
                                        } else {
                                            return Promise.reject(t("kisi.invalidTckn"));
                                        }
                                    } else {
                                        return Promise.reject(t("kisi.invalidTckn"));
                                    }
                                },
                                message: "",
                            },
                        ]}
                    >
                        <Input placeholder={t("your.TCno")} maxLength={11} type="number" />
                    </Form.Item>
                    <Form.Item name="isim" label={t("your.isim")} rules={[{ required: true, message: t("genel.gerekli") }, { whitespace: true }]}>
                        <Input
                            placeholder={t("your.isim")}
                            onInput={(e) => {
                                var result = "";
                                var words = e.target.value.split(" ");
                                words.forEach(function (value, index, array) {
                                    value = value.charAt(0).toUpperCase() + value.slice(1);
                                    result = result.trimStart() + " " + value;
                                });
                                // const reducedArray = words.reduce((acc, curr) => `${acc}${curr.lat},${curr.lon}|` ,'')
                                e.target.value = result.trimStart();
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="soyad"
                        label={t("your.soyad")}
                        rules={[
                            { required: true, message: t("genel.gerekli") },
                            { whitespace: true },
                            {
                                transform: (v) => {
                                    v = v.toUpperCase();
                                },
                            },
                        ]}
                    >
                        <Input placeholder={t("your.soyad")} onInput={(e) => (e.target.value = e.target.value.toUpperCase())} />
                    </Form.Item>
                    <Form.Item name="unvan" label={t("your.unvan")}>
                        <Input placeholder={t("your.unvan")} />
                    </Form.Item>
                    <Form.Item name="dogumYili" label={t("kisi.dogumYili")}>
                        <Input
                            prefix={<CalendarOutlined />}
                            placeholder={t("your.dogumYili")}
                            maxLength={4}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                        />
                    </Form.Item>
                    <Form.Item name="cinsiyet" label={t("your.cinsiyet")} requiredMark="optional">
                        <Select placeholder={t("your.cinsiyet")}>
                            <Select.Option value="E">{t("kisi.erkek")}</Select.Option>
                            <Select.Option value="K">{t("kisi.kadin")}</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="kanGrubu" label={t("your.kanGrubu")} requiredMark="optional">
                        <Select placeholder={t("your.kanGrubu")}>
                            <Select.Option value="0-">0-</Select.Option>
                            <Select.Option value="0+">0+</Select.Option>
                            <Select.Option value="A-">A-</Select.Option>
                            <Select.Option value="A+">A+</Select.Option>
                            <Select.Option value="B-">B-</Select.Option>
                            <Select.Option value="B+">B+</Select.Option>
                            <Select.Option value="AB-">AB-</Select.Option>
                            <Select.Option value="AB+">AB+</Select.Option>
                        </Select>
                    </Form.Item>
                    {errMessage && (
                        <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                            <Alert type="error" message={t(errMessage)} />
                        </Space>
                    )}
                </Form>
            </Content>
        </Layout>
    );
}

export default KisiFirma;
