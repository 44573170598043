import { Form, Input, Layout } from "antd";
import { React } from "react";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import { useKursEkle } from "../hooks/useKurs";

const { Content } = Layout;

function KursEkle() {
    const { t } = useTranslation();
    const { kursEkle } = useKursEkle();

    const onSubmit = (e) => {
        kursEkle({ kursIsmi: e.kursIsmi });
    };

    return (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <Form
                    id="kursEkleForm"
                    autoComplete="off"
                    layout="vertical"
                    style={{ margin: 30 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ");
                        console.log(error);
                    }}
                >
                    <Form.Item
                        name="kursIsmi"
                        label={t("kurs.kursIsmi")}
                        rules={[{ required: true, message: t("genel.gerekli") }, { whitespace: true }]}
                    >
                        <Input placeholder={t("kurs.kursIsmiHint")} />
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
}

export default KursEkle;
