import { Button, Image, Layout, Modal, Dropdown, Space, Tooltip } from "antd";
import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import { BugFilled, ArrowLeftOutlined, LogoutOutlined } from "@ant-design/icons";
import BildirimEkle from "./BildirimEkle";
import { auth } from "../firebase/config";

const { Header, Content } = Layout;

function KmyHeader(props) {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);

    //prettier-ignore
    const items = [
        { label: ( <a onClick={() => { i18n.changeLanguage("tr"); }} >Türkçe</a> ), key: "tr", },
        { label: ( <a onClick={() => { i18n.changeLanguage("en"); }} >English</a>), key: "en", },
        { label: ( <a onClick={() => { i18n.changeLanguage("de"); }} >German</a> ), key: "de", },
     ];

    return (
        <Header
            key="kmyHeaderHeader"
            style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                width: "100%",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#555",
                color: "#FFF",
                fontSize: 18,
            }}
        >
            <Space
                key="kmyHeaderSpace1"
                style={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    alignItems: "center",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Space key="kmyHeaderSpace2">
                    <Tooltip title={t("genel.homepage")} key="homepageTooltip">
                        <Image
                            key="kmyHeaderLogo"
                            src="http://pro.kmysistem.com/images/kmyLogoK.png"
                            style={{ height: 58, width: 40, marginLeft: -45, cursor: "pointer" }}
                            preview={false}
                            onClick={() => {
                                navigate("/");
                            }}
                        />
                    </Tooltip>
                    {props.backURL && (
                        <Tooltip title={t("genel.geri")} key="backTooltip">
                            <Button
                                key="kmyHeaderBackButton"
                                icon={<ArrowLeftOutlined />}
                                type="text"
                                style={{ color: "white" }}
                                onClick={() => {
                                    navigate(props.backURL);
                                }}
                            />
                        </Tooltip>
                    )}
                    {t(`sayfaBaslik.${props.caller}`)}
                </Space>
                <Space key="kmyHeaderSpace3">
                    {props.buttons}
                    <Content style={{ padding: "0px" }}>
                        <Tooltip title="Hata / istek bildir" key="ticketTooltip">
                            <Button
                                key="kmyHeaderTicketButton"
                                type="link"
                                icon={<BugFilled />}
                                style={{ color: "#FFF" }}
                                onClick={() => {
                                    setModalOpen(true);
                                }}
                            />
                        </Tooltip>
                        <Dropdown menu={{ items }} key="languageDropdown">
                            <Button key="kmyHeaderLanguageButton" type="link" style={{ color: "#FFF" }}>
                                {i18n.language.toUpperCase()}
                            </Button>
                        </Dropdown>
                        <Tooltip title={t("login.logout")}>
                            <Button
                                key="logoutButton"
                                type="link"
                                icon={<LogoutOutlined />}
                                style={{ marginRight: -32, color: "#FFF" }}
                                onClick={() => auth.signOut()}
                            />
                        </Tooltip>
                        <Modal
                            key="modalForm"
                            open={modalOpen}
                            onOk={() => {
                                setModalOpen(false);
                            }}
                            onCancel={() => {
                                setModalOpen(false);
                            }}
                            footer={[
                                <Button
                                    key="kmyHeaderCancelButton"
                                    onClick={() => {
                                        setModalOpen(false);
                                    }}
                                    style={{ marginRight: 20 }}
                                >
                                    {t("genel.vazgec")}
                                </Button>,
                                <Button
                                    key="kmyHeaderOkButton"
                                    type="primary"
                                    form="bildirimEkleForm"
                                    htmlType="submit"
                                    onClick={() => {
                                        setModalOpen(false);
                                    }}
                                >
                                    {t("genel.kaydet")}
                                </Button>,
                            ]}
                        >
                            <BildirimEkle key="kmyHeaderBildirimEkle" caller={props.caller} />
                        </Modal>
                    </Content>
                </Space>
            </Space>
        </Header>
    );
}

export default KmyHeader;
