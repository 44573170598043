import ulkeililce from "../constant/ulkeililce.json";
import {
    Form,
    Button,
    Cascader,
    Checkbox,
    DatePicker,
    Divider,
    Input,
    Layout,
    List,
    message,
    Modal,
    notification,
    Popconfirm,
    Select,
    Space,
    Spin,
    Tabs,
    Tooltip,
    Upload,
} from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ImgCrop from "antd-img-crop";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
    PhoneOutlined,
    LoadingOutlined,
    PlusOutlined,
    EyeOutlined,
    EditOutlined,
    LikeFilled,
    DislikeFilled,
    ClockCircleFilled,
} from "@ant-design/icons";
import { useFirmayiGetir, useFirmaGuncelle, useFirmaSil } from "../hooks/useFirma";
import { useBelgeTipleriniGetir } from "../hooks/useBelge";
import { useKisileriGetir } from "../hooks/useKisi";
import locale from "antd/es/date-picker/locale/tr_TR";
import "dayjs/locale/tr.js";
import dayjs from "dayjs";
import KmyHeader from "./KmyHeader";
import BelgeEkle from "./BelgeEkle";
import DocumentUpdate from "./BelgeGuncelle";

const { Content } = Layout;

function FirmaGuncelle() {
    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isPending, setIsPending] = useState(true);
    const [errMessage, setErrMessage] = useState(null);
    const [api, contextHolder] = notification.useNotification();
    const [tmgdOptions, setTmgdOptions] = useState([]);
    const [kmyCustomer, setKmyCustomer] = useState(false);
    const [tmgdk, setTmgdk] = useState(false);
    const [tmgdCustomer, setTmgdCustomer] = useState(false);
    const [uetdsCustomer, setUetdsCustomer] = useState(false);
    const [contactOptions, setContactOptions] = useState([]);
    const [regKey, setRegKey] = useState();
    const [docModalOpen, setDocModalOpen] = useState(false);
    const [docUpdateModalOpen, setDocUpdateModalOpen] = useState(false);
    const [filteredDocSource, setFilteredDocSource] = useState([]);
    const [docProps, setDocProps] = useState();
    const [docTypeList, setDocTypeList] = useState([]);

    const yetkiler = [99999]; //auth.yetkiler;
    const [form] = Form.useForm();
    const { firma } = useFirmayiGetir(id);
    const [logoURL, setLogoURL] = useState();
    const [loading, setLoading] = useState(false);
    const { belgeTipleri } = useBelgeTipleriniGetir(id, "firma");
    const [belgeler, setBelgeler] = useState();
    const [docsDataSource, setDocsDataSource] = useState([]);

    const { kisiler } = useKisileriGetir();
    const { firmaGuncelle } = useFirmaGuncelle(id);
    const { firmaSil } = useFirmaSil(id);

    const firmaKaydetButton = (yetkiler.includes(10201) || yetkiler.includes(99999)) && (
        <Button key="saveButton" type="primary" htmlType="submit" loading={isPending} danger={errMessage} size="small">
            {t("genel.kaydet")}
        </Button>
    );

    const firmaSilButton = (yetkiler.includes(10202) || yetkiler.includes(99999)) && (
        <Popconfirm
            key="deleteConfirm"
            placement="bottomRight"
            title={t("genel.eminMisin")}
            description={t("genel.silinecek")}
            okText={t("genel.sil")}
            cancelText={t("genel.vazgec")}
            onConfirm={() => {
                firmaSil(id);
                navigate("/firmaList");
            }}
            style={{ width: 400 }}
        >
            <Button key="deleteButton" type="primary" danger size="small">
                {t("genel.sil")}
            </Button>
        </Popconfirm>
    );

    const getBelgeler = async () => {
        const accessToken = JSON.parse(localStorage.getItem("kmyToken")) || {};
        fetch(`http://89.19.24.18:3001/Belge/${id}/firma`, {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setBelgeler(data);
            });
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error(t("genel.jpgPngOnly"));
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error(t("genel.lessThan10"));
        }
        return isJpgOrPng && isLt10M;
    };

    const handleUploadChange = (info) => {
        if (info.file.status === "uploading") {
            setLoading(true);
            return;
        }
        if (info.file.status === "done") {
            console.log("image upload done");
        }
    };

    const logoUpload = async ({ onError, onSuccess, file }) => {
        const parts = file.name.split(".");
        const fileExt = parts[parts.length - 1];
        const formData = new FormData();
        formData.append("logo", file, `logo.${fileExt}`);
        const createXHR = () => new XMLHttpRequest();
        fetch(`http://89.19.24.18:3001/Firma/${id}`, {
            body: formData,
            createXHR,
            method: "POST",
        })
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                setLogoURL(res.body);
            });
        setLoading(false);
    };

    useEffect(() => {
        if (kisiler) {
            const dropdownItemsPerson = kisiler.map((item) => ({ value: String(item.kisiID), label: `${item.isim} ${item.soyad}` }));
            setTmgdOptions(dropdownItemsPerson);
            setContactOptions(dropdownItemsPerson);
        }
        getBelgeler();
    }, [kisiler]);

    useEffect(() => {
        setDocTypeList(belgeTipleri);
    }, [belgeTipleri]);

    useEffect(() => {
        setDocsDataSource(belgeler);
        setFilteredDocSource(belgeler);
        setIsPending(false);
    }, [belgeler]);

    useEffect(() => {
        setTimeout(function () {
            getBelgeler();
        }, 1000);
    }, [docModalOpen, docUpdateModalOpen]);

    useEffect(() => {
        if (firma) {
            form.setFieldsValue({
                firmaismi: firma.firmaismi,
                firmaUnvani: firma.firmaUnvani,
                ulke: firma.ulke,
                il: firma.il,
                ilce: firma.ilce,
                adres: firma.adres,
                postaKodu: firma.postaKodu,
                uavt: firma.uavt,
                telefonNo1: firma.telefonNo1,
                telefonNo2: firma.telefonNo2,
                vergiDairesi: firma.vergiDairesi,
                vergiNo: firma.vergiNo,
                tehlikeDerecesi: firma.tehlikeDerecesi,
                sevesoDerecesi: firma.sevesoDerecesi,
                sgkNo: firma.sgkNo,
                mersisNo: firma.mersisNo,
                ticaretSicilNo: firma.ticaretSicilNo,
                kepAdresi: firma.kepAdresi,
                eTebligat: firma.eTebligat,
                naceKodu: firma.naceKodu,
                faaliyetKonusu: firma.faaliyetKonusu,
                faaliyetler: firma.faaliyetler === "" ? null : firma.faaliyetler,
                kmyBitisTarihi: firma.kmyBitisTarihi ? dayjs(firma.kmyBitisTarihi) : null,
                kmyMuhatap: firma.kmyMuhatap === "" ? null : firma.kmyMuhatap,
                uetdsBitisTarihi: firma.uetdsBitisTarihi ? dayjs(firma.uetdsBitisTarihi) : null,
                unetNo: firma.unetNo,
                uetdsPassword: firma.uetdsPassword,
                uetdsMuhatap: firma.uetdsMuhatap === "" ? null : firma.uetdsMuhatap,
                tmgdBitisTarihi: firma.tmgdBitisTarihi ? dayjs(firma.tmgdBitisTarihi) : null,
                tmgdKapsam: firma.tmgdKapsam,
                tmgdMuhatap: firma.tmgdMuhatap === "" ? null : firma.tmgdMuhatap,
                firmaMuhatap: firma.firmaMuhatap === "" ? null : firma.firmaMuhatap,
                tmfbMuhatap: firma.tmfbMuhatap === "" ? null : firma.tmfbMuhatap,
                dgLogistics: firma.dgLogistics === "" ? null : firma.dgLogistics,
                uabYetkiBolgesi: firma.uabYetkiBolgesi === "" ? null : firma.uabYetkiBolgesi,
                asilTmgd: firma.asilTmgd,
                yedekTmgd: firma.yedekTmgd,
                ulkeililce: [firma.ulke, firma.il, firma.ilce],
            });
            setUetdsCustomer(firma.uetdsMusterisiMi);
            setTmgdCustomer(firma.tmgdMusteriMi);
            setKmyCustomer(firma.kmyMusterisiMi);
            setTmgdk(firma.tmgdKurumuMu);
            setRegKey(`${id.substr(0, 2)} ${id.substr(2, 2)} ${id.substr(4, 2)} ${id.substr(6, 2)}`);
            setLogoURL(firma.logoURL);
            setIsPending(false);
        }
    }, [firma]);

    useEffect(() => {}, [logoURL]);

    const onSubmit = (e) => {
        setIsPending(true);
        if (e.ulkeililce == null) {
            e.ulke = "";
            e.il = "";
            e.ilce = "";
            e.ulkeililce = "";
        } else {
            e.ulke = e.ulkeililce[0];
            e.il = e.ulkeililce[1];
            e.ilce = e.ulkeililce[2];
            e.ulkeililce = "";
        }
        e.firmaUnvani = e.firmaUnvani.toUpperCase();
        e.tmgdMusteriMi = tmgdCustomer;
        e.uetdsMusterisiMi = uetdsCustomer;
        e.kmyMusterisiMi = kmyCustomer;
        e.tmgdKurumuMu = tmgdk;

        firmaGuncelle({ id, e });
        setIsPending(false);
        api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
    };

    return isPending ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout style={{ margin: 0, padding: 0 }}>
            <Content style={{ padding: "0px" }}>
                <Form
                    form={form}
                    disabled={!yetkiler.includes(10201) && !yetkiler.includes(99999)}
                    autoComplete="off"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 14 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={(error) => {
                        console.log("onFinishFailed : ");
                        console.log(error);
                    }}
                >
                    <KmyHeader caller="firmaGuncelle" buttons={[firmaKaydetButton, firmaSilButton]} backURL="/firmaList" />
                    {contextHolder}
                    <Space direction="horizontal" style={{ width: "100%", margin: 20, justifyContent: "center" }}>
                        <ImgCrop
                            showGrid="true"
                            rotationSlider="true"
                            modalTitle="Resmi düzenle"
                            modalCancel={t("genel.vazgec")}
                            modalOk={t("genel.kaydet")}
                            minZoom={0.2}
                            aspect={3 / 1}
                            cropShape="rect"
                        >
                            <Upload
                                name="logo"
                                className="avatar-uploader"
                                showUploadList={false}
                                customRequest={logoUpload}
                                beforeUpload={beforeUpload}
                                onChange={handleUploadChange}
                            >
                                {logoURL ? (
                                    <img src={`${logoURL}`} alt="logo" style={{ width: 300, height: 100 }} />
                                ) : (
                                    <button style={{ border: 1, background: "none" }} type="button">
                                        {loading ? <LoadingOutlined style={{ cursor: "pointer" }} /> : <PlusOutlined style={{ cursor: "pointer" }} />}
                                        <div style={{ marginTop: 8, marginBottom: -20, cursor: "pointer" }}>Upload</div>
                                    </button>
                                )}
                            </Upload>
                        </ImgCrop>
                    </Space>
                    <Tabs
                        centered
                        tabPosition="top"
                        type="card"
                        defaultActiveKey="1"
                        items={[
                            {
                                key: "1",
                                label: t("firma.tabCommon"),
                                children: (
                                    <>
                                        <Form.Item
                                            name="firmaismi"
                                            label={t("firma.firmaismi")}
                                            rules={[{ required: true, message: t("genel.gerekli") }, { whitespace: true }]}
                                            style={{ marginTop: 20 }}
                                        >
                                            <Input
                                                placeholder={t("firma.firmaismiHint")}
                                                maxLength={255}
                                                addonAfter={<Tooltip title="kayıt anahtarı">{regKey}</Tooltip>}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="firmaUnvani"
                                            label={t("firma.firmaUnvani")}
                                            rules={[{ required: true, message: t("genel.gerekli") }, { whitespace: true }]}
                                        >
                                            <Input placeholder={t("firma.firmaUnvaniHint")} maxLength={255} style={{ textTransform: "uppercase" }} />
                                        </Form.Item>
                                        <Form.Item
                                            name="ulkeililce"
                                            label={t("firma.ulkeililce")}
                                            rules={[{ required: true, message: t("genel.gerekli") }]}
                                        >
                                            <Cascader options={ulkeililce} placeholder={t("genel.seciniz")} />
                                        </Form.Item>
                                        <Form.Item name="adres" label={t("firma.adres")}>
                                            <Input placeholder={t("firma.adresHint")} maxLength={255} />
                                        </Form.Item>
                                        <Form.Item name="postaKodu" label={t("firma.postaKodu")}>
                                            <Input placeholder={t("firma.postaKodu")} maxLength={10} />
                                        </Form.Item>
                                        <Form.Item name="telefonNo1" label={t("firma.telefonNo")}>
                                            <PhoneInput
                                                disabled={!yetkiler.includes(10201) && !yetkiler.includes(99999)}
                                                defaultCountry={"TR"}
                                                onChange={(val) => {}}
                                                className={
                                                    !yetkiler.includes(10201) && !yetkiler.includes(99999)
                                                        ? "ant-input css-dev-only-do-not-override-mzwlov input-phone-number ant-input-disabled"
                                                        : "ant-input css-dev-only-do-not-override-mzwlov input-phone-number ant-input-outlined"
                                                }
                                                placeholder={t("firma.telefonNoHint")}
                                            />
                                        </Form.Item>
                                        <Form.Item name="telefonNo2" label={t("firma.telefonNo")}>
                                            <PhoneInput
                                                disabled={!yetkiler.includes(10201) && !yetkiler.includes(99999)}
                                                defaultCountry={"TR"}
                                                onChange={(val) => {}}
                                                className={
                                                    !yetkiler.includes(10201) && !yetkiler.includes(99999)
                                                        ? "ant-input css-dev-only-do-not-override-mzwlov input-phone-number ant-input-disabled"
                                                        : "ant-input css-dev-only-do-not-override-mzwlov input-phone-number ant-input-outlined"
                                                }
                                                placeholder={t("firma.telefonNoHint")}
                                            />
                                        </Form.Item>
                                        <Form.Item name="firmaMuhatap" label={t("firma.firmaMuhatap")}>
                                            <Select placeholder={t("firma.firmaMuhatap")} deleteButton={true}>
                                                <Select.Option key="tmgdMuhatapSelect" value="0">
                                                    {t("genel.seciniz")}
                                                </Select.Option>
                                                {contactOptions.map((item, index) => (
                                                    <Select.Option value={item.value} key={index}>
                                                        {item.label}
                                                    </Select.Option>
                                                ))}{" "}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="tmfbMuhatap" label={t("firma.tmfbMuhatap")} style={{ marginTop: 20 }}>
                                            <Select placeholder={t("firma.tmfbMuhatap")}>
                                                <Select.Option key="tmgdMuhatapSelect" value="0">
                                                    {t("genel.seciniz")}
                                                </Select.Option>
                                                {contactOptions.map((item, index) => (
                                                    <Select.Option value={item.value} key={index}>
                                                        {item.label}
                                                    </Select.Option>
                                                ))}{" "}
                                            </Select>
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "2",
                                label: t("firma.tabGovernment"),
                                children: (
                                    <>
                                        <Form.Item name="vergiDairesi" label={t("firma.vergiDairesi")} style={{ marginTop: 20 }}>
                                            <Input
                                                placeholder={t("firma.vergiDairesiHint")}
                                                maxLength={50}
                                                onInput={(e) => {
                                                    e.target.value = e.target.value.toLocaleUpperCase("tr-TR");
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item name="vergiNo" label={t("firma.vergiNo")}>
                                            <Input placeholder={t("firma.vergiNoHint")} maxLength={15} />
                                        </Form.Item>
                                        <Form.Item name="sgkNo" label={t("firma.sgkNo")} rules={[{ whitespace: true }]}>
                                            <Input placeholder={t("firma.sgkNo")} maxLength={35} />
                                        </Form.Item>
                                        <Form.Item name="mersisNo" label={t("firma.mersisNo")} rules={[{ whitespace: true }]}>
                                            <Input placeholder={t("firma.mersisNo")} maxLength={20} />
                                        </Form.Item>
                                        <Form.Item name="ticaretSicilNo" label={t("firma.ticaretSicilNo")} rules={[{ whitespace: true }]}>
                                            <Input placeholder={t("firma.ticaretSicilNo")} maxLength={20} />
                                        </Form.Item>
                                        <Form.Item name="uavt" label={t("firma.uavt")}>
                                            <Input placeholder={t("firma.uavtHint")} maxLength={10} />
                                        </Form.Item>
                                        <Form.Item name="kepAdresi" label={t("firma.kepAdresi")}>
                                            <Input placeholder={t("firma.kepAdresiHint")} maxLength={60} />
                                        </Form.Item>
                                        <Form.Item name="eTebligat" label={t("firma.eTebligat")}>
                                            <Input placeholder={t("firma.eTebligat")} maxLength={60} />
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "3",
                                label: t("firma.tabAction"),
                                children: (
                                    <>
                                        <Form.Item name="tehlikeDerecesi" label={t("firma.tehlikeDerecesi")} style={{ marginTop: 20 }}>
                                            <Select placeholder={t("genel.seciniz")}>
                                                <Select.Option value="0">{t("genel.seciniz")}</Select.Option>
                                                <Select.Option value="1">{t("firma.tehlikeDerecesi1")}</Select.Option>
                                                <Select.Option value="2">{t("firma.tehlikeDerecesi2")}</Select.Option>
                                                <Select.Option value="3">{t("firma.tehlikeDerecesi3")}</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="sevesoDerecesi" label={t("firma.sevesoDerecesi")}>
                                            <Select placeholder={t("genel.seciniz")}>
                                                <Select.Option value="0">{t("genel.seciniz")}</Select.Option>
                                                <Select.Option value="1">{t("firma.sevesoDerecesi1")}</Select.Option>
                                                <Select.Option value="2">{t("firma.sevesoDerecesi2")}</Select.Option>
                                                <Select.Option value="3">{t("firma.sevesoDerecesi3")}</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="naceKodu" label={t("firma.naceKodu")} maxLength={8}>
                                            <Input placeholder={t("firma.naceKoduHint")} />
                                        </Form.Item>
                                        <Form.Item name="faaliyetKonusu" label={t("firma.faaliyetKonusu")} suffix={<PhoneOutlined />}>
                                            <Select placeholder={t("genel.seciniz")}>
                                                <Select.Option key="1" value="1">
                                                    {t("faaliyetKonusu.Akaryakıt / LPG İstasyonu")}
                                                </Select.Option>
                                                <Select.Option key="2" value="2">
                                                    {t(`faaliyetKonusu.Fabrika / İmalathane`)}
                                                </Select.Option>
                                                <Select.Option key="3" value="3">
                                                    {t(`faaliyetKonusu.Şantiye / Maden Ocağı`)}
                                                </Select.Option>
                                                <Select.Option key="4" value="4">
                                                    {t(`faaliyetKonusu.Geri Dönüşüm Tesisi / Atık`)}
                                                </Select.Option>
                                                <Select.Option key="5" value="5">
                                                    {t(`faaliyetKonusu.Kamu Kurum / Kuruluşu`)}
                                                </Select.Option>
                                                <Select.Option key="6" value="6">
                                                    {t(`faaliyetKonusu.Sevkiyat (İrtibat) / Lojistik / Ambar`)}
                                                </Select.Option>
                                                <Select.Option key="7" value="7">
                                                    {t(`faaliyetKonusu.Turizm / Konaklama`)}
                                                </Select.Option>
                                                <Select.Option key="8" value="8">
                                                    {t(`faaliyetKonusu.Tüp Bayi`)}
                                                </Select.Option>
                                                <Select.Option key="9" value="9">
                                                    {t(`faaliyetKonusu.Sağlık Kuruluşu`)}
                                                </Select.Option>
                                                <Select.Option key="10" value="10">
                                                    {t(`faaliyetKonusu.Tehlikeli Madde Satış Yeri`)}
                                                </Select.Option>
                                                <Select.Option key="11" value="11">
                                                    {t(`faaliyetKonusu.Hazır Beton Tesisi`)}
                                                </Select.Option>
                                                <Select.Option key="12" value="12">
                                                    {t(`faaliyetKonusu.Bakım Servisi`)}
                                                </Select.Option>
                                                <Select.Option key="13" value="13">
                                                    {t(`faaliyetKonusu.Depolama/Dolum`)}
                                                </Select.Option>
                                                <Select.Option key="14" value="14">
                                                    {t(`faaliyetKonusu.Taşımacı`)}
                                                </Select.Option>
                                                <Select.Option key="15" value="15">
                                                    {t(`faaliyetKonusu.TMGDK`)}
                                                </Select.Option>
                                                <Select.Option key="16" value="16">
                                                    {t(`faaliyetKonusu.Diğer`)}
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="faaliyetler" label={t("firma.faaliyetler")}>
                                            <Select mode="tags" placeholder={t("genel.seciniz")}>
                                                <Select.Option key="1" value="1">
                                                    {t(`faaliyetler.Dolduran`)}
                                                </Select.Option>
                                                <Select.Option key="2" value="2">
                                                    {t(`faaliyetler.Paketleyen`)}
                                                </Select.Option>
                                                <Select.Option key="3" value="3">
                                                    {t(`faaliyetler.Yükleyen`)}
                                                </Select.Option>
                                                <Select.Option key="4" value="4">
                                                    {t(`faaliyetler.Gönderen`)}
                                                </Select.Option>
                                                <Select.Option key="5" value="5">
                                                    {t(`faaliyetler.Alıcı`)}
                                                </Select.Option>
                                                <Select.Option key="6" value="6">
                                                    {t(`faaliyetler.Boşaltan`)}
                                                </Select.Option>
                                                <Select.Option key="7" value="7">
                                                    {t(`faaliyetler.Taşımacı`)}
                                                </Select.Option>
                                                <Select.Option key="8" value="8">
                                                    {t(`faaliyetler.Tank Konteyner/Portatif Tank İşletmecisi`)}
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Divider>
                                            <Checkbox
                                                name="tmgdKurumuMu"
                                                checked={tmgdk}
                                                onChange={(val) => {
                                                    setTmgdk(val.target.checked);
                                                }}
                                            >
                                                {t("firma.tmgdKurumuMu")}
                                            </Checkbox>
                                        </Divider>
                                        <Form.Item name="uabYetkiBolgesi" label={t("firma.uabYetkiBolgesi")}>
                                            <Select placeholder={t("genel.seciniz")} mode="multiple" disabled={!tmgdk}>
                                                <Select.Option value="1">I.Bölge (İstanbul)</Select.Option>
                                                <Select.Option value="2">II.Bölge (Ankara)</Select.Option>
                                                <Select.Option value="3">III.Bölge (İzmir)</Select.Option>
                                                <Select.Option value="4">IV.Bölge (Bursa)</Select.Option>
                                                <Select.Option value="9">IX.Bölge (Samsun)</Select.Option>
                                                <Select.Option value="5">V.Bölge (Adana)</Select.Option>
                                                <Select.Option value="6">VI.Bölge (Antalya)</Select.Option>
                                                <Select.Option value="7">VII.Bölge (Gaziantep)</Select.Option>
                                                <Select.Option value="8">VIII.Bölge (Diyarbakır)</Select.Option>
                                                <Select.Option value="10">X.Bölge (Erzurum)</Select.Option>
                                                <Select.Option value="11">XI.Bölge (Trabzon)</Select.Option>
                                                <Select.Option value="12">XII.Bölge (Sivas)</Select.Option>
                                                <Select.Option value="13">XIII.Bölge (Bolu)</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "4",
                                label: t("firma.tabSoftware"),
                                children: (
                                    <>
                                        <Divider>
                                            <Checkbox
                                                name="kmyMusterisiMi"
                                                checked={kmyCustomer}
                                                onChange={(val) => {
                                                    setKmyCustomer(val.target.checked);
                                                }}
                                            >
                                                {t("firma.kmyMusterisiMi")}
                                            </Checkbox>
                                        </Divider>
                                        <Form.Item name="kmyBitisTarihi" label={t("firma.bitisTarihi")}>
                                            <DatePicker
                                                style={{ width: "100%" }}
                                                locale={locale}
                                                format={"YYYY/MM/DD"}
                                                placeholder={t("firma.bitisTarihiHint")}
                                                disabled={!kmyCustomer}
                                            />
                                        </Form.Item>
                                        <Form.Item name="kmyMuhatap" label={t("firma.ilgiliKisi")}>
                                            <Select mode="multiple" placeholder={t("firma.ilgiliKisiHint")} disabled={!kmyCustomer}>
                                                <Select.Option key="kmyMuhatapSelect" value="0" disabled={true}>
                                                    {t("genel.seciniz")}
                                                </Select.Option>
                                                {contactOptions.map((item, index) => (
                                                    <Select.Option value={item.value} key={index}>
                                                        {item.label}
                                                    </Select.Option>
                                                ))}{" "}
                                            </Select>
                                        </Form.Item>
                                        <Divider>
                                            <Checkbox
                                                name="uetdsMusterisiMi"
                                                checked={uetdsCustomer}
                                                onChange={(val) => {
                                                    setUetdsCustomer(val.target.checked);
                                                }}
                                            >
                                                {t("firma.uetdsMusterisiMi")}
                                            </Checkbox>
                                        </Divider>
                                        <Form.Item name="uetdsBitisTarihi" label={t("firma.bitisTarihi")}>
                                            <DatePicker
                                                style={{ width: "100%" }}
                                                locale={locale}
                                                format={"YYYY/MM/DD"}
                                                placeholder={t("firma.bitisTarihiHint")}
                                                disabled={!uetdsCustomer}
                                            />
                                        </Form.Item>
                                        <Form.Item name="unetNo" label={t("firma.unetNo")}>
                                            <Input placeholder={t("firma.unetNoHint")} maxLength={15} type="number" disabled={!uetdsCustomer} />
                                        </Form.Item>
                                        <Form.Item name="uetdsPassword" label={t("firma.uetdsPassword")}>
                                            <Input.Password
                                                placeholder={t("firma.uetdsPasswordHint")}
                                                maxLength={15}
                                                autoComplete="false"
                                                disabled={!uetdsCustomer}
                                            />
                                        </Form.Item>
                                        <Form.Item name="uetdsMuhatap" label={t("firma.ilgiliKisi")}>
                                            <Select mode="multiple" placeholder={t("firma.ilgiliKisiHint")} disabled={!uetdsCustomer}>
                                                <Select.Option key="uetdsMuhatapSelect" value="0" disabled={true}>
                                                    {t("genel.seciniz")}
                                                </Select.Option>
                                                {contactOptions.map((item, index) => (
                                                    <Select.Option value={item.value} key={index}>
                                                        {item.label}
                                                    </Select.Option>
                                                ))}{" "}
                                            </Select>
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "5",
                                label: t("firma.tabTmgd"),
                                children: (
                                    <>
                                        <Form.Item name="tmgdMusterisiMi" label={t("firma.tmgdMusterisiMi")} style={{ marginTop: 20 }}>
                                            <Checkbox
                                                checked={tmgdCustomer}
                                                onChange={(val) => {
                                                    setTmgdCustomer(val.target.checked);
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item name="tmgdBitisTarihi" label={t("firma.bitisTarihi")}>
                                            <DatePicker
                                                style={{ width: "100%" }}
                                                locale={locale}
                                                format={"YYYY/MM/DD"}
                                                placeholder={t("firma.bitisTarihiHint")}
                                                disabled={!tmgdCustomer}
                                            />
                                        </Form.Item>
                                        <Form.Item name="tmgdKapsam" label={t("firma.tmgdKapsam")}>
                                            <Select mode="tags" placeholder={t("genel.seciniz")} disabled={!tmgdCustomer}>
                                                <Select.Option key="ADR" value="ADR">
                                                    ADR
                                                </Select.Option>
                                                <Select.Option key="RID" value="RID">
                                                    RID
                                                </Select.Option>
                                                <Select.Option key="IMDG" value="IMDG">
                                                    IMDG
                                                </Select.Option>
                                                <Select.Option key="DGR" value="DGR">
                                                    DGR
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="asilTmgd" label={t("firma.asilTmgd")}>
                                            <Select placeholder={t("firma.asilTmgdHint")} disabled={!tmgdCustomer}>
                                                <Select.Option key="tmgd1select" value="0" disabled={true}>
                                                    {t("genel.seciniz")}
                                                </Select.Option>
                                                {tmgdOptions.map((item, index) => (
                                                    <Select.Option value={item.value} key={index}>
                                                        {item.label}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="yedekTmgd" label={t("firma.yedekTmgd")}>
                                            <Select placeholder={t("firma.yedekTmgdHint")} disabled={!tmgdCustomer}>
                                                <Select.Option key="tmgd2select" value="0" disabled={true}>
                                                    {t("genel.seciniz")}
                                                </Select.Option>
                                                {tmgdOptions.map((item, index) => (
                                                    <Select.Option value={item.value} key={index}>
                                                        {item.label}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="tmgdMuhatap" label={t("firma.ilgiliKisi")}>
                                            <Select mode="multiple" placeholder={t("firma.ilgiliKisiHint")} disabled={!tmgdCustomer}>
                                                <Select.Option key="tmgdMuhatapSelect" value="0" disabled={true}>
                                                    {t("genel.seciniz")}
                                                </Select.Option>
                                                {contactOptions.map((item, index) => (
                                                    <Select.Option value={item.value} key={index}>
                                                        {item.label}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                key: "6",
                                label: t("firma.tabDocuments"),
                                children: (
                                    <List
                                        style={{ margin: 20 }}
                                        header={
                                            <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => {
                                                        setDocModalOpen(true);
                                                    }}
                                                >
                                                    {t("genel.belgeEkle")}
                                                </Button>
                                            </Space>
                                        }
                                        bordered
                                        dataSource={filteredDocSource}
                                        renderItem={(item) => (
                                            <List.Item key={item.id}>
                                                <Space style={{ width: "100%", justifyContent: "space-between" }}>
                                                    <Space>
                                                        <Button
                                                            type="link"
                                                            icon={<EyeOutlined />}
                                                            style={{ color: "black", marginRight: -10 }}
                                                            onClick={() => {
                                                                window.open(item.belgeURL, "_blank");
                                                            }}
                                                        />
                                                        <Button
                                                            type="text"
                                                            icon={<EditOutlined />}
                                                            onClick={() => {
                                                                setDocProps({
                                                                    id: item.id,
                                                                    docType: item.belgeTipiID,
                                                                    belgeIsmi: item.belgeIsmi,
                                                                    gecerlikTarihi: item.gecerlikTarihi,
                                                                });
                                                                setDocUpdateModalOpen(true);
                                                            }}
                                                        />
                                                        {item.belgeIsmi}
                                                    </Space>
                                                    <Tooltip
                                                        title={
                                                            item.belgeDurumu === "hayir" ? item.durumAciklamasi : t(`belgeDurumu.${item.belgeDurumu}`)
                                                        }
                                                    >
                                                        {item.belgeDurumu === "yeni" ? (
                                                            <ClockCircleFilled style={{ color: "#aaa" }} />
                                                        ) : item.belgeDurumu === "hayir" ? (
                                                            <DislikeFilled style={{ color: "red" }} />
                                                        ) : (
                                                            <LikeFilled style={{ color: "green" }} />
                                                        )}
                                                    </Tooltip>
                                                </Space>
                                            </List.Item>
                                        )}
                                    />
                                ),
                            },
                        ]}
                    />
                    <Modal
                        key="docModalForm"
                        open={docModalOpen}
                        onOk={() => {
                            setDocModalOpen(false);
                        }}
                        onCancel={() => {
                            setDocModalOpen(false);
                        }}
                        footer={[
                            <Button
                                key="cancel"
                                onClick={() => {
                                    setDocModalOpen(false);
                                }}
                                style={{ marginRight: 20 }}
                            >
                                {t("genel.vazgec")}
                            </Button>,
                            <Button
                                key="ok"
                                type="primary"
                                form="docAddForm"
                                htmlType="submit"
                                onClick={() => {
                                    setDocModalOpen(false);
                                }}
                            >
                                {t("genel.kaydet")}
                            </Button>,
                        ]}
                    >
                        <BelgeEkle owner="firma" id={id} typeList={docTypeList} />
                    </Modal>
                    <Modal
                        key="docUpdateModalForm"
                        open={docUpdateModalOpen}
                        onOk={() => {
                            setDocUpdateModalOpen(false);
                        }}
                        onCancel={() => {
                            setDocUpdateModalOpen(false);
                        }}
                        footer={[
                            <Button
                                key="cancel"
                                onClick={() => {
                                    setDocUpdateModalOpen(false);
                                }}
                                style={{ marginRight: 20 }}
                            >
                                {t("genel.vazgec")}
                            </Button>,
                            <Button
                                key="ok"
                                type="primary"
                                form="docUpdateForm"
                                htmlType="submit"
                                onClick={() => {
                                    setDocUpdateModalOpen(false);
                                }}
                            >
                                {t("genel.kaydet")}
                            </Button>,
                        ]}
                    >
                        <DocumentUpdate owner="firma" fieldValues={docProps} id={id} typeList={docTypeList} />
                    </Modal>
                </Form>
            </Content>
        </Layout>
    );
}

export default FirmaGuncelle;
