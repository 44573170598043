import { Button, Dropdown, Layout, Modal, Tooltip } from "antd";
import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import { BugFilled } from "@ant-design/icons";
import BildirimEkle from "./BildirimEkle";

const { Header, Content } = Layout;

function Languages(props) {
    const { t, i18n } = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);

    //prettier-ignore
    const items = [
        { label: ( <a onClick={() => { i18n.changeLanguage("tr"); }} >Türkçe</a> ), key: "tr", },
        { label: ( <a onClick={() => { i18n.changeLanguage("en"); }} >English</a>), key: "en", },
        { label: ( <a onClick={() => { i18n.changeLanguage("de"); }} >German</a> ), key: "de", },
     ];

    return (
        <>
            <Content style={{ padding: "0px" }}>
                <Tooltip title="Hata / istek bildir">
                    <Button
                        type="link"
                        icon={<BugFilled />}
                        style={{ color: "#FFF" }}
                        onClick={() => {
                            setModalOpen(true);
                        }}
                    />
                </Tooltip>
                <Dropdown menu={{ items }}>
                    <Button type="link" style={{ marginRight: -40, color: "#FFF" }}>
                        {i18n.language.toUpperCase()}
                    </Button>
                </Dropdown>
                <Modal
                    key="modalForm"
                    open={modalOpen}
                    onOk={() => {
                        setModalOpen(false);
                    }}
                    onCancel={() => {
                        setModalOpen(false);
                    }}
                    footer={[
                        <Button
                            key="cancel"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            style={{ marginRight: 20 }}
                        >
                            {t("genel.vazgec")}
                        </Button>,
                        <Button
                            key="ok"
                            type="primary"
                            form="bildirimEkleForm"
                            htmlType="submit"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                        >
                            {t("genel.kaydet")}
                        </Button>,
                    ]}
                >
                    <BildirimEkle caller={props.caller} />
                </Modal>
            </Content>
        </>
    );
}

export default Languages;
