import { useGetUserInfo } from "./useGetUserInfo";
import { useEffect, useState } from "react";

export const useKursBolumEkle = () => {
    const kursBolumEkle = async ({ kursID, kursBolumIsmi }) => {
        fetch(`http://89.19.24.18:3001/KursBolum/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                kursID: kursID,
                kursBolumIsmi: kursBolumIsmi ? kursBolumIsmi : "",
                kursBolumIcerik: "",
                videoURL: "",
                ucretsizMi: false,
                yayinlandiMi: false,
                hangiSirada: 0,
            }),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("add cevap : ", data);
            });
    };

    return { kursBolumEkle };
};

export const useKursBolumGuncelle = (id, e) => {
    const kursBolumGuncelle = async ({ id, e }) => {
        fetch(`http://89.19.24.18:3001/KursBolum/byId/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("update cevap : ", data);
            });
    };
    return { kursBolumGuncelle };
};

export const useKursBolumSil = (id) => {
    const kursBolumSil = async () => {
        fetch(`http://89.19.24.18:3001/KursBolum/byId/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("delete cevap : ", data);
            });
    };
    return { kursBolumSil };
};

export const useKursBolumleriniGetir = (id) => {
    const [kursBolumleri, setKursBolumleri] = useState([]);
    const kursBolumleriniGetir = async () => {
        fetch(`http://89.19.24.18:3001/KursBolum/kurs/${id}`)
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setKursBolumleri(data);
            });
        return () => kursBolumleri;
    };

    useEffect(() => {
        kursBolumleriniGetir();
    }, []);

    return { kursBolumleri };
};

export const useKursBolumunuGetir = (id) => {
    const [kursBolumu, setKursBolumu] = useState([]);
    const kursBolumunuGetir = async () => {
        if (id) {
            fetch(`http://89.19.24.18:3001/KursBolum/byId/${id}`)
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    setKursBolumu(data);
                });
        }
        return () => kursBolumu;
    };

    useEffect(() => {
        kursBolumunuGetir();
    }, []);

    return { kursBolumu };
};
