import { useGetUserInfo } from "./useGetUserInfo";
import { useEffect, useState } from "react";

export const useKursSoruEkle = () => {
    const kursSoruEkle = async ({ kursID, soru }) => {
        console.log("kursID : ", kursID, " Question : ", soru);
        fetch(`http://89.19.24.18:3001/KursSoru/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                kursID: kursID,
                soru: soru ? soru : "",
                soruURL: "",
                dogruCevap: "",
                dogruCevapURL: "",
                yanlisCevap1: "",
                yanlisCevap1URL: "",
                yanlisCevap2: "",
                yanlisCevap2URL: "",
                yanlisCevap3: "",
                yanlisCevap3URL: "",
                yanlisCevap4: "",
                yanlisCevap4URL: "",
            }),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("add cevap : ", data);
            });
    };

    return { kursSoruEkle };
};

export const useKursSoruGuncelle = (id, e) => {
    const kursSoruGuncelle = async ({ id, e }) => {
        fetch(`http://89.19.24.18:3001/KursSoru/byId/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("update cevap : ", data);
            });
    };
    return { kursSoruGuncelle };
};

export const useKursSoruSil = (id) => {
    const kursSoruSil = async () => {
        fetch(`http://89.19.24.18:3001/KursSoru/byId/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("delete cevap : ", data);
            });
    };
    return { kursSoruSil };
};

export const useKursSorulariniGetir = (id) => {
    const [kursSorulari, setKursSorulari] = useState([]);
    const kursSorulariniGetir = async () => {
        fetch(`http://89.19.24.18:3001/KursSoru/kurs/${id}`)
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setKursSorulari(data);
            });
        return () => kursSorulari;
    };

    useEffect(() => {
        kursSorulariniGetir();
    }, []);

    return { kursSorulari };
};

export const useKursSorusunuGetir = (id) => {
    const [kursSorusu, setKursSorusu] = useState([]);
    const kursSorusunuGetir = async () => {
        if (id) {
            fetch(`http://89.19.24.18:3001/KursSoru/byId/${id}`)
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    setKursSorusu(data);
                });
        }
        return () => kursSorusu;
    };

    useEffect(() => {
        kursSorusunuGetir();
    }, []);

    return { kursSorusu };
};
