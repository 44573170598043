import { Form, Button, Input, message, notification, Popconfirm, Spin, Tabs, Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import { useKursSorusunuGetir, useKursSoruGuncelle, useKursSoruSil } from "../hooks/useKursSoru";
import KmyHeader from "./KmyHeader";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const { TextArea } = Input;

function KursSoruGuncelle() {
    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isPending, setIsPending] = useState();
    const [errMessage, setErrMessage] = useState(null);
    const [api, contextHolder] = notification.useNotification();

    const yetkiler = [99999]; //auth.permissions;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [kursID, setKursID] = useState();
    const [videoURL, setVideoURL] = useState();

    const { kursSorusu } = useKursSorusunuGetir(id);
    const { kursSoruGuncelle } = useKursSoruGuncelle(id);
    const { kursSoruSil } = useKursSoruSil(id);

    const kursSoruKaydetButon = (yetkiler.includes(10201) || yetkiler.includes(99999)) && (
        <Button type="primary" htmlType="submit" loading={isPending} danger={errMessage} key="soruSaveButton">
            {t("genel.kaydet")}
        </Button>
    );

    const kursSoruSilButon = (yetkiler.includes(10202) || yetkiler.includes(99999)) && (
        <Popconfirm
            key="kursSoruSilOnay"
            placement="bottomRight"
            title={t("genel.eminMisin")}
            description={t("genel.silinecek")}
            okText={t("genel.sil")}
            cancelText={t("genel.vazgec")}
            onConfirm={() => {
                kursSoruSil(id);
                navigate("/soruList");
            }}
            style={{ width: 400 }}
        >
            <Button type="primary" danger key="kursSoruSilButton">
                {t("genel.sil")}
            </Button>
        </Popconfirm>
    );

    useEffect(() => {
        if (kursSorusu) {
            console.log("kurssorusu : ", kursSorusu);
            form.setFieldsValue({
                soru: kursSorusu.soru,
                dogruCevap: kursSorusu.dogruCevap,
                yanlisCevap1: kursSorusu.yanlisCevap1,
                yanlisCevap2: kursSorusu.yanlisCevap2,
                yanlisCevap3: kursSorusu.yanlisCevap3,
                yanlisCevap4: kursSorusu.yanlisCevap4,
            });
            setVideoURL(kursSorusu.videoURL);
            setKursID(kursSorusu.kursID);
            setLoading(false);
        }
    }, [kursSorusu]);

    const onSubmit = (e) => {
        setIsPending(true);
        kursSoruGuncelle({ id, e });
        setIsPending(false);
        api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
    };

    // resim yükleme kısmı
    const beforeUpload = (file) => {
        const isVideo = file.type === "video/x-msvideo" || file.type === "video/mp4" || file.type === "video/quicktime" || file.type === "video/mpeg";
        if (!isVideo) {
            message.error(t("genel.videoOnly"));
        }
        const isLt500M = file.size / 1024 / 1024 < 500;
        if (!isLt500M) {
            message.error(t("genel.lessThan500"));
        }
        return isVideo && isLt500M;
    };

    const handleUploadChange = (info) => {
        if (info.file.status === "uploading") {
            //setLoading(true);
            return;
        }
        if (info.file.status === "done") {
            //setLoading(false);
            console.log("video uploaded");
        }
    };

    const videoUpload = async ({ onError, onSuccess, file }) => {
        setLoading(true);
        const parts = file.name.split(".");
        const fileExt = parts[parts.length - 1];
        const formData = new FormData();
        formData.append("doc", file, `video_${id}.${fileExt}`);
        formData.append("soruID", id);
        const createXHR = () => new XMLHttpRequest();
        fetch(`http://89.19.24.18:3001/KursSoru/${kursID}`, {
            body: formData,
            createXHR,
            method: "POST",
        })
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                console.log(res, "res");
                setLoading(false);
            });
    };

    return loading ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Form
            form={form}
            disabled={!yetkiler.includes(10201) && !yetkiler.includes(99999)}
            autoComplete="off"
            onFinish={(values) => {
                onSubmit(values);
            }}
            onFinishFailed={(error) => {
                console.log("onFinishFailed : ", error);
            }}
        >
            <KmyHeader caller="soruUpdate" buttons={[kursSoruKaydetButon, kursSoruSilButon]} backURL={`/kursUpdate/${kursID}`} />
            {contextHolder}
            <Tabs
                centered
                type="card"
                defaultActiveKey="1"
                style={{ marginTop: 20, border: 0 }}
                items={[
                    {
                        key: "1",
                        label: t("kursSoru.tabSoru"),
                        children: (
                            <Form.Item name="soru">
                                <ReactQuill style={{ backgroundColor: "#FFF", minHeight: 500, margin: 20 }} />
                            </Form.Item>
                        ),
                    },
                    {
                        key: "2",
                        label: t("kursSoru.tabDogru"),
                        children: (
                            <Form.Item name="dogruCevap">
                                <ReactQuill style={{ backgroundColor: "#EFE", minHeight: 500, margin: 20 }} />
                            </Form.Item>
                        ),
                    },
                    {
                        key: "3",
                        label: t("kursSoru.tabYanlis1"),
                        children: (
                            <Form.Item name="yanlisCevap1">
                                <ReactQuill style={{ backgroundColor: "#FEE", minHeight: 500, margin: 20 }} />
                            </Form.Item>
                        ),
                    },
                    {
                        key: "4",
                        label: t("kursSoru.tabYanlis2"),
                        children: (
                            <Form.Item name="yanlisCevap2">
                                <ReactQuill style={{ backgroundColor: "#FEE", minHeight: 500, margin: 20 }} />
                            </Form.Item>
                        ),
                    },
                    {
                        key: "5",
                        label: t("kursSoru.tabYanlis3"),
                        children: (
                            <Form.Item name="yanlisCevap3">
                                <ReactQuill style={{ backgroundColor: "#FEE", minHeight: 500, margin: 20 }} />
                            </Form.Item>
                        ),
                    },
                    {
                        key: "6",
                        label: t("kursSoru.tabYanlis4"),
                        children: (
                            <Form.Item name="yanlisCevap4">
                                <ReactQuill style={{ backgroundColor: "#FEE", minHeight: 500, margin: 20 }} />
                            </Form.Item>
                        ),
                    },
                ]}
            />
        </Form>
    );
}

export default KursSoruGuncelle;
